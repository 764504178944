import React, { useCallback, useContext, useMemo, useState } from 'react'

import { Button, Col, InputNumber, message, notification, Row } from 'antd'
import { connect } from 'react-redux'
import { getTranslate } from 'react-localize-redux'
import { faSave } from '@fortawesome/pro-solid-svg-icons'

import { GlobalContext } from '../../GlobalProvider'
import { isValid } from '../../../utils/validators'
import { isNotNullObject } from '../../../utils'
import { Quota, QUOTA_VALIDATORS, QuotaProperties } from '../../../utils/entities/quota'
import YearlyQuotaChecker from './YearlyQuotaChecker'
import QuickDualDatePickers from '../../../Components/institution/Quotas/shared/QuickDualDatePickers'
import SchoolsSelector from '../../../Components/shared/InternshipsManager/Selectors/SchoolsSelector'
import SchoolSectionSelector from '../../../Components/shared/InternshipsManager/Selectors/SchoolSectionSelector'
import SectionToYearSelector from '../../../Components/shared/InternshipsManager/Selectors/SectionToYearSelector'
import TooltipIconButton from '../../../Components/antd/Buttons/TooltipIconButton'

import '../../../assets/quotas.scss'

export const BODY_STYLE = { display: 'flex', flexDirection: 'column', height: '93%', width: '100%' }
export const SELECT_STYLE = { width: '200px' }

const COLSPAN_VALUE = 12

message.config({
  top: 20,
  duration: 3,
  maxCount: 1,
  placement: 'topRight'
})

const mapStateToProps = state => ({ t: getTranslate(state.locale) })

const QuotaForm = ({ data, onSubmit, onCancel, t }) => {
  const { sections } = useContext(GlobalContext)

  const [isYearly, setIsYearly] = useState(false)
  const [formData, setFormData] = useState(new Quota(isNotNullObject(data) ? data : { total: 0 }))

  const handleSubmit = event => {
    const errors = isValid(formData, QUOTA_VALIDATORS)

    if(errors.keys.length > 0) {
      const errorMessages = t('quota_form.error_fields', {
        fields: errors.keys.map(key => t('common.' + key)).join(', ')
      })

      notification.error({message: errorMessages})
    } else if (formData.startDate > formData.endDate) {
      message.error({message: t('quota_form.error_dates')})
    } else {
      onSubmit(new Quota(formData))
    }
  }

  const handleDatesChange = useCallback((dates, _) => setFormData(formData => ({ ...formData, startDate: dates[0], endDate: dates[1] })), [setFormData])

  const handleSectionChange = useCallback((value, option, section) => setFormData(formData => ({ ...formData, section, sectionToYear: null })), [setFormData])

  const updateFormData = useCallback(key => (value, option, entity) => setFormData(formData => ({ ...formData, [key]: isNotNullObject(entity) ? entity : value })), [setFormData])

  const toggleIsYearly = useCallback(() => setIsYearly(current => !current), [setIsYearly])

  const isSubmitDisabled = useMemo(() => (formData.school == null || formData.total == null || formData.startDate == null || formData.endDate == null), [formData.school, formData.total, formData.startDate, formData.endDate])

  return (
    <div className='allocate-quota-form'>
      <QuickDualDatePickers
        startDate={formData.startDate}
        endDate={formData.endDate}
        period={formData.period}
        onDatesChange={handleDatesChange}
      />
      <Row>
        <Col span={COLSPAN_VALUE}>
          <h4>
            <b>
              {t('quota_form.is_yearly.dd')}
            </b>
          </h4>
        </Col>
        <Col span={COLSPAN_VALUE}>
          <YearlyQuotaChecker onCheck={toggleIsYearly} />
        </Col>
      </Row>
      <Row>
        <Col span={COLSPAN_VALUE}>
          <h4>
            <b>
              {t('quota_form.places_to_allocate.dd')}
            </b>
          </h4>
        </Col>
        <Col span={COLSPAN_VALUE}>
          <InputNumber
            min={0}
            value={formData.total}
            onChange={updateFormData(QuotaProperties.TOTAL)}
          />
        </Col>
      </Row>
      <Row>
        <Col span={COLSPAN_VALUE}>
          <h4>
            <b>
              {t('common.school.dd')}
            </b>
          </h4>
        </Col>
        <Col span={COLSPAN_VALUE}>
          <SchoolsSelector
            style={SELECT_STYLE}
            multiple={false}
            values={formData.school?.abbreviation}
            onChange={updateFormData(QuotaProperties.SCHOOL)}
          />
        </Col>
      </Row>
      <Row>
        <Col span={COLSPAN_VALUE}>
          <h4>
            <b>
              {t('common.section.dd')}
            </b>
          </h4>
        </Col>
        <Col span={COLSPAN_VALUE}>
          <SchoolSectionSelector
            style={SELECT_STYLE}
            schoolSections={sections}
            values={formData.section?.abbreviation}
            onChange={handleSectionChange}
          />
        </Col>
      </Row>
      <Row>
        <Col span={COLSPAN_VALUE}>
          <h4>
            <b>
              {t('common.school_year.dd')}
            </b>
          </h4>
        </Col>
        <Col span={COLSPAN_VALUE}>
          <SectionToYearSelector
            style={SELECT_STYLE}
            disabled={!formData.section}
            multiple={false}
            section={formData.section}
            values={formData.sectionToYear?.schoolYearName}
            onChange={updateFormData(QuotaProperties.SECTION_TO_YEAR)}
          />
        </Col>
      </Row>
      <div className='allocation-form-footer'>
        <TooltipIconButton
          icon={faSave}
          disabled={isSubmitDisabled}
          type='primary'
          placement='left'
          text={t('modal.ok_button.save')}
          title={isSubmitDisabled &&
            t('quota_form_modal.add_button.disabled_tooltip', {
              fields: [
                t('common.period'),
                t('quota_form.places_to_allocate'),
                t('common.school')
              ].join(', ')
            })}
          onClick={handleSubmit}
        />
        <Button onClick={onCancel}>
          {t('modal.cancel_button')}
        </Button>
      </div>
    </div>
  )
}

export default connect(mapStateToProps)(QuotaForm)
