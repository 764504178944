import React, { useEffect, useMemo, useState } from 'react'

import moment from 'moment'
import { Tabs } from 'antd'
import { connect } from 'react-redux'
import { getTranslate } from 'react-localize-redux'
import { Routes } from '../../../routes'
import { getInstitutionOptions } from '../../../reducers/InstitutionOptionsReducer/actions'
import Analytics from '../../../utils/analytics'
import QuotasProvider from '../../../Providers/Quotas/QuotasProvider'
import PlacesDefiner from '../../../Components/institution/Quotas/definition/PlacesDefiner'
import AllocationProvider from '../../../Providers/Quotas/AllocationProvider'
import PlacesAllocationContainer from '../../../Components/institution/Quotas/allocation/PlacesAllocationContainer'
import SchoolQuotasView from './SchoolQuotasView/SchoolQuotasView'
import { getEnvironment } from '../../../utils'
import { Environments } from '../../../config/environments'
import { InstitutionOptions } from '../../../utils/constants'
import DefaultContainer from '../../../HOC/DefaultContainer'

const { TabPane } = Tabs

const mapStateToProps = state => ({
  t: getTranslate(state.locale),
  institutionOptions: getInstitutionOptions(state.institutionOptions)
})

const QuotasView = ({ institutionOptions, t }) => {
  const [date, setDate] = useState(moment())

  const env = getEnvironment()

  useEffect(() => {
    Analytics.pageView(Routes.QUOTAS)
  }, [])

  const useQuotaPerPause = useMemo(() => {
    return !!institutionOptions.find(option => option.optionType.type === InstitutionOptions.QUOTA_PER_PAUSE)?.optionEnabled
  }, [institutionOptions])

  return (
    <QuotasProvider>
      <Tabs defaultActiveKey='1' animated={false} className='flex-fill' destroyInactiveTabPane>
        <TabPane tab={t('Define places')} key='1' className='flex-fill'>
          <PlacesDefiner
            date={date}
            pausesRequired={useQuotaPerPause}
            onDateChange={setDate}
          />
        </TabPane>
        <TabPane tab={t('Allow places to schools')} key='2' className='flex-fill'>
          <AllocationProvider>
            <PlacesAllocationContainer />
          </AllocationProvider>
        </TabPane>
        {env !== Environments.PROD && (
          <TabPane tab={t('Allow places to schools V2')} key='3' className='flex-fill'>
            <AllocationProvider>
              <DefaultContainer>
                <SchoolQuotasView />
              </DefaultContainer>
            </AllocationProvider>
          </TabPane>
        )}
      </Tabs>
    </QuotasProvider>
  )
}

export default connect(mapStateToProps)(QuotasView)
