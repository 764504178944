import React, { useContext, useMemo } from 'react'
import { GlobalContext } from '../../../../Providers/GlobalProvider'
import EntitiesSelector from './EntitiesSelector'

const SectionsSelector = ({ disabled, multiple, style = {}, values, onChange }) => {
  const { sections } = useContext(GlobalContext)

  const sectionOptions = useMemo(() => sections.map(section => ({
    label: section.abbreviation,
    value: section.id,
    tooltip: section.name,
    entity: section
  })), [sections])

  return (
    <EntitiesSelector
      disabled={disabled}
      entityOptions={sectionOptions}
      multiple={multiple}
      showSearch
      style={style}
      values={values}
      onChange={onChange}
    />
  )
}

export default SectionsSelector
