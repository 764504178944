import React, { useMemo, useState } from 'react'

import { connect } from '../../../reducers/Dispatchers'
import { Drawer } from 'antd'
import { getTranslate } from 'react-localize-redux'
import { getUser } from '../../../reducers/UserReducer'
import { OrderDirections, Contexts } from '../../../utils/constants'
import { TableParameters } from '../../../utils/entities/tableParameters'
import DataTable from '../../../Components/antd/Tables/DataTable/DataTable'

const SCHOOL_COLUMNS = ['section', 'section_abbreviation', 'school_year_name']
const INSTITUTION_COLUMNS = ['sector']

const CONTEXT_COLUMNS = {
  [Contexts.SCHOOL]: SCHOOL_COLUMNS,
  [Contexts.INSTITUTION]: INSTITUTION_COLUMNS
}

const TRANSLATE_PREFIX_CONTEXT = 'context.property.'

const DEFAULT_TABLE_PARAMS = {
  search: '',
  order: {
    by: '',
    orderDirection: OrderDirections.ASC
  },
  pageSize: 20
}

const mapStateToProps = state => ({ t: getTranslate(state.locale), user: getUser(state.getUser) })

/**
 * @param {(columns:string[], translateKey:string) => string[]} props.formatColumns
 */
const ContactPersonAssignments = ({ t, user, selectedContact, onClose, formatColumns }) => {
  const [tableParameters, setTableParameters] = useState(new TableParameters({ ...DEFAULT_TABLE_PARAMS, order: { by: CONTEXT_COLUMNS[user.context][0] } }))

  const dataFormat = useMemo(() => {
    let map
    let sort

    if (user.context === Contexts.INSTITUTION) {
      map = entity => ({ sector: entity.name })
      sort = (a, b) => a.sector.localeCompare(b.sector)
    } else if (user.context === Contexts.SCHOOL) {
      map = entity => ({
        school_year_name: entity.sectionToYear?.schoolYearName ?? '',
        section_abbreviation: entity.sectionToYear?.sectionAbbreviation ?? entity.abbreviation,
        section: entity.sectionToYear?.sectionName ?? entity.name
      })
      sort = (a, b) => a.school_year_name.localeCompare(b.school_year_name)
    }

    return { map, sort }
  }, [user.context, selectedContact])

  const contextColumns = useMemo(() =>
    formatColumns(CONTEXT_COLUMNS[user.context], TRANSLATE_PREFIX_CONTEXT)
  , [user.context, selectedContact])

  const displayedData = useMemo(() => {
    if (selectedContact == null) return []

    const { order, search } = tableParameters

    let toDisplay = selectedContact
      .nestedEntities
      .map(dataFormat.map)
      .sort(dataFormat.sort)

    if (search) {
      toDisplay = toDisplay.filter(entity =>
        Object
          .values(entity)
          .some(value => String(value).toLowerCase().includes(search.toLowerCase()))
      )
    }

    if (order.by) {
      toDisplay.sort((a, b) =>
        order.direction === OrderDirections.DESC
          ? b[order.by].localeCompare(a[order.by])
          : a[order.by].localeCompare(b[order.by])
      )
    }

    return toDisplay
  }, [selectedContact, tableParameters])

  return (
    <Drawer
      title={t('contact_persons_assignments.title') + ` (${selectedContact?.email})`}
      width='75%'
      onClose={onClose}
      visible={selectedContact != null}
    >
      {selectedContact != null && (
        <DataTable
          columns={contextColumns}
          data={displayedData}
          parameters={tableParameters}
          metadata={tableParameters}
          onParametersChange={setTableParameters}
        />
      )}
    </Drawer>
  )
}

export default connect(mapStateToProps)(ContactPersonAssignments)
