import React, {useContext, useEffect, useMemo, useState} from 'react'

import GlobalQuotaCard from './GlobalQuotaCard'
import QuotaCard from './QuotaCard'
import { DEFAULT_DRAWER_WIDTH, GLOBAL_PLACES_KEY } from '../utils/Constants'
import moment from 'moment'
import {Divider, Drawer, Modal} from 'antd'
import GlobalPlacesDrawer from './GlobalPlacesDrawer'
import PauseCard from './PauseCard'
import { createNewPauseQuota, createNewQuota } from '../../../../utils/entities/entityFactory'
import { getUser } from '../../../../reducers/UserReducer'
import { getTranslate } from 'react-localize-redux'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { addQuota, removeQuota, updateQuota } from '../../../../reducers/QuotasReducer/actions'
import { createPauseQuota, deletePauseQuota, getPauseQuotas, updatePauseQuota } from '../../../../reducers/PauseQuotasReducer/PauseQuotasReducer'
import { getInstitutionOptions } from '../../../../reducers/InstitutionOptionsReducer/actions'
import { getAppStates } from '../../../../reducers/AppStates/actions'
import { InstitutionOptions } from '../../../../utils/constants'
import {GlobalContext} from "../../../../Providers/GlobalProvider";
import {getSectorSections} from "../../../../utils/api/sector";
import Loading from "../../../../HOC/Loading";

import '../../../../assets/smart-table.scss'
import '../../../../assets/quotas.scss'

const DEFAULT_TITLE = 'Attribute places'
const PAUSES_TITLE = 'Attribute places to pauses'
const DEFAULT_CONFIG = { initialTotal: 0, total: 0, remaining: 0, startDate: moment(), endDate: moment() }

const mapStateToProps = state => ({
  institutionOptions: getInstitutionOptions(state.institutionOptions),
  pauseActivated: getAppStates(state).pauseActivated,
  pauseQuotas: getPauseQuotas(state),
  t: getTranslate(state.locale),
  user: getUser(state.getUser)
})

const mapDispatchToProps = dispatch => ({
  addQuota: bindActionCreators(addQuota, dispatch),
  createPauseQuota: bindActionCreators(createPauseQuota, dispatch),
  deletePauseQuota: bindActionCreators(deletePauseQuota, dispatch),
  removeQuota: bindActionCreators(removeQuota, dispatch),
  updateQuota: bindActionCreators(updateQuota, dispatch),
  updatePauseQuota: bindActionCreators(updatePauseQuota, dispatch)
})

const PlacesManagingDrawer = ({
  data, institutionOptions, pauseActivated, pauseQuotas, pauses, sector, user, visible, onClose, t, addQuota, createPauseQuota, deletePauseQuota,
  removeQuota, updateQuota, updatePauseQuota
}) => {
  const gc = useContext(GlobalContext)

  const [config, setConfig] = useState({ ...DEFAULT_CONFIG })
  const [newPlaces, setNewPlaces] = useState(null)
  const [openGlobalModal, setOpenGlobalModal] = useState(false)
  const [displayWarning, setDisplayWarning] = useState(false)
  const [sectorSections, setSectorSections] = useState([])
  const [loading, setLoading] = useState(false)

  const options = useMemo(() => {
    const options = {
      autoIncrement: false,
      pauseUsed: false
    }

    institutionOptions.forEach(o => {
      if (o.optionType.type === InstitutionOptions.QUOTA_PER_PAUSE) {
        options.pauseUsed = true
      } else if (o.optionType.type === InstitutionOptions.UPDATE_GLOBAL_QUOTA) {
        options.autoIncrement = o.optionEnabled
      }
    })

    return options
  }, [institutionOptions])

  const Card = useMemo(() => pauseActivated ? PauseCard : QuotaCard, [pauseActivated])
  const quotas = useMemo(() => {
    let quotas = []

    if (pauseActivated && pauses) {
      quotas = pauses.quotas.map(q => pauseQuotas[q]).filter(q => !!q)
    } else if (data) {
      quotas = data.quotas.filter(q => !!q.section)
    }

    return quotas
  }, [pauseActivated, pauseQuotas, data, pauses])
  const sections = useMemo(() => sectorSections.length === 0 ? gc.sections : sectorSections, [gc.sections, sectorSections])

  useEffect(() => {
    if (data) {
      configureHeaderAndDates(data, pauses, pauseActivated)
    }
  }, [data, pauses, pauseActivated])

  useEffect(() => {
    if (sector) {
      setLoading(true)
      getSectorSections(user, sector)
        .then(({data}) => setSectorSections(data))
        .finally(() => setLoading(false))
    }
  }, [sector, user])

  const configureHeaderAndDates = (data, pauses, pauseActivated) => {
    let newValues = decrementRemainingByState(
      data,
      pauses,
      {
        ...DEFAULT_CONFIG, initialTotal: data.total, total: data.total, remaining: data.total, startDate: null, endDate: null
      },
      pauseActivated
    )

    if (data.quotas) {
      data.quotas.forEach(quota => {
        if (!quota.section) {
          newValues = getGlobalValues(newValues, quota)
        }
      })
    }

    setConfig(newValues)
  }

  const decrementRemainingByState = (data, pauses, newValues, pauseActivated) => {
    if (pauseActivated) {
      if (pauses && pauses.total) {
        newValues.remaining -= pauses.total
      }

      return newValues
    }

    if (data.details) {
      Object.keys(data.details).forEach(sectionId => {
        if (sectionId !== GLOBAL_PLACES_KEY) {
          newValues.remaining -= data.details[sectionId]
        }
      })
    }

    return newValues
  }

  const getGlobalValues = (values, quota) => {
    const { startDate, endDate } = values
    const quotaStartDate = moment(quota.startDate)
    const quotaEndDate = moment(quota.endDate)

    return {
      ...values,
      startDate: startDate === null || startDate < quotaStartDate ? quotaStartDate : startDate,
      endDate: endDate === null || endDate > quotaEndDate ? quotaEndDate : endDate
    }
  }

  const createNewPlaces = () => {
    const newPlaces = pauseActivated ? createNewPauseQuota(sector, config.startDate, config.endDate) : createNewQuota(sector, config.startDate, config.endDate)

    setNewPlaces(newPlaces)
  }

  const handleOnClose = () => {
    setConfig({ ...DEFAULT_CONFIG })
    setNewPlaces(null)

    onClose()
  }

  const handlePlacesSave = quota => {
    const method = pauseActivated ? createPauseQuota : addQuota

    method(quota, user)
    setNewPlaces(null)
    postModification()
  }

  const handlePlacesUpdate = quota => {
    const method = pauseActivated ? updatePauseQuota : updateQuota

    method(quota, user)
    postModification()
  }

  const postModification = () => {
    if (options.autoIncrement && (config.total !== config.initialTotal || config.remaining < 0)) {
      balancingQuotas()
    } else if (config.remaining < 0) {
      setDisplayWarning(true)
    }
  }

  const balancingQuotas = () => {
    const globalQuota = data.quotas.find(q => !q.section)

    if (globalQuota) {
      updateQuota({ id: globalQuota.id, total: config.total - config.remaining }, user)
    }
  }

  const handlePlacesSuppression = quota => {
    const method = pauseActivated ? deletePauseQuota : removeQuota

    method(quota, user)
  }

  const incrementRemaining = value => {
    if (options.autoIncrement && config.remaining === 0 && (config.total - value) >= config.initialTotal) {
      setConfig({ ...config, total: config.total - value })
    } else {
      setConfig({ ...config, remaining: config.remaining + value })
    }
  }

  return (
    <div>
      <Drawer
        title={(
          <div className='places-creater-drawer-title'>
            {sector &&
              <h4 style={{textAlign: 'center', margin: '0px'}}>
                {t(pauseActivated ? PAUSES_TITLE : DEFAULT_TITLE) + ': ' + sector.name}
              </h4>
            }
          </div>
        )}
        width={DEFAULT_DRAWER_WIDTH}
        onClose={handleOnClose}
        visible={visible}
      >
        {loading && (<Loading />)}
        <div className='places-managing-drawer-body'>
          <GlobalQuotaCard
            warning={(config.total !== config.initialTotal || config.remaining < 0)}
            warningMessage={t('global_quota_card.warning.' + (options.autoIncrement ? 'auto' : 'manual'))}
            pauseActivated={pauseActivated}
            total={config.total}
            remaining={config.remaining}
            startDate={config.startDate}
            endDate={config.endDate}
            pauseUsed={options.pauseUsed}
            onPlacesAllocated={createNewPlaces}
            onGlobalPlacesDisplayed={() => setOpenGlobalModal(true)}
            allocateButtonDisable={!!newPlaces}
          />
          <div className={newPlaces || quotas.length ? 'places-managing-drawer-inner-body' : ''}>
            {newPlaces && (
              <Card
                data={newPlaces}
                sections={sections}
                onDelete={() => setNewPlaces(null)}
                onSave={handlePlacesSave}
                onPlacesChanged={incrementRemaining}
              />
            )}
            {quotas.map(q => (
              <Card
                key={'quota-card-' + q.id}
                data={q}
                sections={sections}
                onDelete={handlePlacesSuppression}
                onSave={handlePlacesUpdate}
                onPlacesChanged={incrementRemaining}
              />
            ))}
          </div>
        </div>
      </Drawer>
      <GlobalPlacesDrawer
        visible={openGlobalModal}
        quotas={data?.quotas ?? []}
        onClose={() => setOpenGlobalModal(false)}
      />
      <Modal
        visible={displayWarning}
        title={t('quota_warning_modal.title')}
        onOk={() => {
          balancingQuotas()
          setDisplayWarning(false)
        }}
        onCancel={() => setDisplayWarning(false)}
        okText={t('modal.ok_button.balancing')}
        cancelText={t('modal.cancel_button')}
      >
        {t('quota_warning_modal.body')}
      </Modal>
    </div>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(PlacesManagingDrawer)
