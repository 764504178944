import React, { useState, useEffect, useContext } from 'react'

import QuotaCard from './QuotaCard'
import { Drawer } from 'antd'
import { deleteQuota, duplicateQuota, getAll, updateQuotaOld } from '../../../../utils/api/quota'
import { getUser } from '../../../../reducers/UserReducer'
import { connect } from 'react-redux'
import { getTranslate } from 'react-localize-redux'
import { QuotasContext } from '../../../../Providers/Quotas/QuotasProvider'
import { isObject } from '../../../../utils'
import { DATE_FORMAT_API } from '../../../../utils/constants'
import Loading from '../../../shared/Loading'
import { getMaxPlacesByPeriod } from '../utils/DataDerivers'
import { Quota } from '../../../../utils/entities/quota'
import { getFrontDate, onError, onSuccess } from '../../../../utils/apiHelper'

import '../assets/quotas.scss'

const DRAWER_WIDTH = '550px'
const DRAWER_TITLE = 'Manage quotas'
const BODY_STYLE = { display: 'flex', flexDirection: 'column', height: '93%', width: '100%' }

const mapStateToProps = state => {
  return {
    t: getTranslate(state.locale),
    user: getUser(state.getUser)
  }
}

const AllocationModificationDrawer = ({ period, places, school, section, sector, user, visible, onClose, t }) => {
  const { schools, getSchoolYears } = useContext(QuotasContext)

  const [quotas, setQuotas] = useState([])
  const [schoolYears, setSchoolYears] = useState([])
  const [loading, setLoading] = useState(true)
  const [placesPool, setPlacesPool] = useState(0)
  const [invalidation, setInvalidation] = useState(false)

  useEffect(() => {
    if (school && sector && visible) {
      setLoading(true)

      getAll(
        user,
        {
          'start-date': period.startDate.format(DATE_FORMAT_API),
          'end-date': period.endDate.format(DATE_FORMAT_API),
          sector: sector.id,
          section: section === null ? null : section.id,
          schools: [school.id],
          contexts: ['school', 'sectionToYear']
        }
      ).then(json => {
        if (json && json.data) {
          setQuotas(json.data)
        }

        setLoading(false)
      })
    }
  }, [school, section, sector, visible])

  useEffect(() => {
    if (isObject(section)) {
      getSchoolYears(section).then(setSchoolYears)
    } else {
      setSchoolYears([])
    }
  }, [section])

  useEffect(() => {
    if (places.length > 0 && period) {
      setPlacesPool(getMaxPlacesByPeriod(places, period.startDate, period.endDate, period, section))
    }
  }, [places, period, section])

  const renderDrawerTitle = () => {
    return (<h3 style={{ textAlign: 'center', margin: '0px' }}> {t(DRAWER_TITLE)} </h3>)
  }

  const handleQuotaSaving = quota => {
    const uptatedQuota = getQuotaObject(quota)

    updateQuotaOld(uptatedQuota, user).then(json => {
      if (json && json.data) {
        onSuccess(t('The quota has been successfully updated'))

        setInvalidation(true)
        setQuotas(quotas.map(q => {
          if (q.id === json.data.id) {
            return quota
          }

          return q
        }))
      } else {
        onError(t('An error was encountered when updating the quota'))
      }
    })
  }

  const handleQuotaDuplication = quota => {
    const uptatedQuota = getQuotaObject(quota)

    duplicateQuota(uptatedQuota, user).then(json => {
      if (json && json.data) {
        onSuccess(t('The quota has been successfully dupicated'))

        setInvalidation(true)
        setQuotas([...quotas, { ...quota, id: json.data.id }])
      } else {
        onError(t('An error was encountered when duplicating the quota'))
      }
    })
  }

  const handleQuotaDeletion = quota => {
    const uptatedQuota = getQuotaObject(quota)

    deleteQuota(uptatedQuota, user).then(json => {
      if (json.status === 'success') {
        onSuccess(t('The quota has been successfully deleted'))

        setInvalidation(true)
        setQuotas(quotas.filter(q => q.id !== quota.id))
      } else {
        onError(t('An error was encountered when deleting the quota'))
      }
    })
  }

  const getQuotaObject = quota => {
    return new Quota({ ...quota, startDate: getFrontDate(quota.startDate), endDate: getFrontDate(quota.endDate) })
  }

  return (
    <Drawer
      title={renderDrawerTitle()}
      width={DRAWER_WIDTH}
      visible={visible}
      onClose={() => onClose(section, invalidation)}
      bodyStyle={BODY_STYLE}
      afterVisibleChange={visible => {
        if (!visible) {
          setQuotas([])
          setInvalidation(false)
        }
      }}
    >
      <div className='quota-modification-drawer-body'>
        {loading && (
          <Loading size='1x' />
        )}
        {!loading && quotas.map((q, index) => {
          return (
            <QuotaCard
              key={`q-card-${q.id}`}
              quota={q}
              remainingPlaces={placesPool}
              schools={schools}
              schoolYears={schoolYears}
              onDelete={handleQuotaDeletion}
              onDuplicate={handleQuotaDuplication}
              onSave={handleQuotaSaving}
            />
          )
        })}
      </div>
    </Drawer>
  )
}

export default connect(mapStateToProps)(AllocationModificationDrawer)
