import { requestMultipart, requestWithPromise } from '..'
import { HttpMethods, makeQueryString, makeQueryStringFromObject } from '../apiHelper'
import moment from 'moment'
import apiClient from './apiClient'
import { Quota } from '../entities/quota'

/** 
 * @deprecated 2025-03-19
 * @see createQuota instead
 */
export const createQuotaOld = (quota, user) => {
  return requestWithPromise(
    '/api/quotas',
    HttpMethods.POST,
    quota.toBody,
    user
  )
}

/** @param {Quota} quota */
export const createQuota = (user, quota) => apiClient.post(user, 'api/quotas', quota.toBody)

export const duplicateQuota = (quota, user) => {
  return requestWithPromise(
    `/api/quotas/${quota.id}/duplicate`,
    HttpMethods.POST,
    {},
    user
  )
}

/** 
 * @deprecated 2025-03-19
 * @see updateQuota instead
 */
export const updateQuotaOld = (quota, user) => {
  return requestWithPromise(
    `/api/quotas/${quota.id}`,
    HttpMethods.PATCH,
    quota.toBody,
    user
  )
}

/** @param {Quota} quota */
export const updateQuota = (user, quota) => apiClient.patch(user, `api/quotas/${quota.id}`, quota.toBody)

export const deleteQuota = (quota, user) => {
  return requestWithPromise(
    `/api/quotas/${quota.id}`,
    HttpMethods.DELETE,
    null,
    user,
    { catchError: false }
  )
}

export const getInternshipsQuotas = (user, internships, parameters = { period: { startDate: moment(), endDate: moment() }, precision: 'year' }) => {
  const queryStringObject = {
    startDate: parameters.period.startDate,
    endDate: parameters.period.endDate,
    internships: internships.map(i => i.id),
    precision: parameters.precision
  }

  return requestWithPromise(
    `/quota/internships${makeQueryString(queryStringObject)}`,
    HttpMethods.GET,
    null,
    user
  )
}

export const getAll = (user, parameters) => {
  return requestWithPromise(
    `/api/quotas${makeQueryStringFromObject(parameters)}`,
    HttpMethods.GET,
    null,
    user
  )
}

export const importQuotas = (user, institution, language, file, optionsToDisable = {}) => {
  return requestMultipart(
    `/import/institutions/${institution.id}/quotas`,
    HttpMethods.POST,
    { ...optionsToDisable, file, language },
    user,
    true
  )
}

export const addQuotasFromArray = (user, body) => {
  return requestWithPromise(
    '/api/quotas',
    HttpMethods.POST,
    body,
    user
  )
}
