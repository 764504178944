import React from 'react'

import { Select } from 'antd'
import { getTranslate } from 'react-localize-redux'
import { connect } from 'react-redux'

const Option = Select.Option

const mapStateToProps = state => ({ t: getTranslate(state.locale) })

const SelectInstitutionFilter = ({ institutions, value, onChange, t }) => {
  return (
    <Select
      showSearch
      placeholder={t('institutions_filter.placeholder')}
      style={{ width: '170px' }}
      value={value}
      onChange={onChange}
    >
      <Option key='-1' value={null}>/</Option>
      {institutions.map((i, index) => {
        return (
          <Option key={index} value={i.id} title={i.name}>{i.name}</Option>
        )
      })}
    </Select>
  )
}

export default connect(mapStateToProps)(SelectInstitutionFilter)
