import React, { useMemo } from 'react'

import EntitiesSelector from './EntitiesSelector'

const SectorSelector = ({ disabled, sectors, style = {}, values, onChange }) => {
  const options = useMemo(() => sectors.map(s => ({
    label: s.name,
    value: s.id,
    tooltip: s.name,
    entity: s
  })), [sectors])

  return (
    <EntitiesSelector
      disabled={disabled}
      entityOptions={options}
      multiple='default'
      showSearch
      style={style}
      values={values}
      onChange={onChange}
    />
  )
}

export default SectorSelector
