import React, { useCallback, useState } from 'react'

import { getTranslate } from 'react-localize-redux'
import { connect } from 'react-redux'
import { getUser } from '../../reducers/UserReducer'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faIdCard } from '@fortawesome/pro-solid-svg-icons'
import ErrorMessage from '../../Components/shared/Messages/ErrorMessage'
import { ValidationFields, isValid } from '../../utils/validators'
import { Input, Modal } from 'antd'
import { onError, onSuccess } from '../../utils/apiHelper'
import { DEFAULT_ERROR, DEFAULT_STYLE } from './utils'
import { ActType } from '../../utils/entities/actType'
import { createActTypeCategory, updateActTypeCategory } from '../../utils/api/actTypeCategory'
import { ACT_TYPE_CATEGORY_VALIDATORS } from '../../utils/entities/actTypeCategory'
import { TableActions } from '../../Components/antd/Tables/DataTable/utils/actions'

const mapStateToProps = state => ({ t: getTranslate(state.locale), user: getUser(state.getUser) })

const ActTypeCategoryFormModal = ({ children, categories = [], user, useSelector, onTriggerAction, onSave, t }) => {
  const [error, setError] = useState(DEFAULT_ERROR)
  const [selectedCategory, setSelectedCategory] = useState(null)
  const [visible, setVisible] = useState(false)

  const handleSubmit = useCallback(() => {
    const errors = isValid(setSelectedCategory, ACT_TYPE_CATEGORY_VALIDATORS)

    if (errors.keys.length === 0) {
      const body = new ActType(selectedCategory).toRequestBody()

      if (selectedCategory.id !== -1) {
        delete body.school
      }

      const promise = selectedCategory.id === -1 ? createActTypeCategory(user, body) : updateActTypeCategory(user, selectedCategory, body)
      const action = selectedCategory.id === -1 ? TableActions.CREATE : TableActions.EDIT

      promise.then(json => {
        if (json?.data) {
          onSuccess(t(`act_type_category_form_modal.${action}.success`))
          setVisible(false)

          if (typeof onSave === 'function') {
            onSave(json.data, action)
          }
        }
      }).catch(e => {
        onError(t(`act_type_category_form_modal.${action}.error`))
      })
    } else {
      const details = {}

      Object.keys(errors.messages).forEach(key => {
        details[t(key)] = errors.messages[key].map(m => t(m))
      })

      setError({
        ...error,
        title: t('form.errors'),
        details,
        visible: true,
        missingFields: errors.keys
      })
    }
  }, [t, user, selectedCategory, onSave, onError, onSuccess, setVisible])

  const handleActionTrigger = useCallback((entity, action) => {
    if (action === TableActions.CREATE_ACT_TYPE_CATEGORY || action === TableActions.EDIT_ACT_TYPE_CATEGORY) {
      setSelectedCategory(entity)
      setVisible(true)
    } else if (typeof onTriggerAction === 'function') {
      onTriggerAction(entity, action)
    }
  }, [setSelectedCategory, setVisible, onTriggerAction])

  return (
    <>
      {React.cloneElement(children, { onTriggerAction: handleActionTrigger })}
      <Modal
        visible={visible}
        onCancel={() => setVisible(false)}
        onOk={handleSubmit}
        title={t('act_type_category_form_modal.title')}
        cancelText={t('modal.cancel_button')}
        okText={t(selectedCategory && selectedCategory.id === -1 ? 'modal.ok_button.create' : 'modal.ok_button.save')}
        okButtonProps={{ disabled: selectedCategory === null }}
        afterClose={() => {
          setError(DEFAULT_ERROR)
          setSelectedCategory(null)
        }}
      >
        {selectedCategory !== null && (
          <div className='user-form'>
            {error.visible && (
              <ErrorMessage title={error.title} details={error.details} />
            )}
            <div className='form-item'>
              <div className='form-label'>
                <label> <FontAwesomeIcon icon={faIdCard} /> {t('form_modal.label.name')} </label>
                <span> {t('form.label.required')} </span>
              </div>
              <Input
                style={error.missingFields.includes(ValidationFields.NAME) ? error.style : DEFAULT_STYLE}
                value={selectedCategory.name}
                onChange={e => setSelectedCategory({ ...selectedCategory, name: e.target.value })}
              />
            </div>
          </div>
        )}
      </Modal>
    </>
  )
}

export default connect(mapStateToProps)(ActTypeCategoryFormModal)
