import React, { useContext, useMemo, useCallback } from 'react'

import { getTranslate } from 'react-localize-redux'
import { connect } from 'react-redux'
import { Button, Select } from 'antd'
import { faEdit, faPlus } from '@fortawesome/pro-solid-svg-icons'
import { TIME_PERIODS_TYPE } from '../../../../utils/timePeriods'
import { QuotaFormModalContext } from '../../../../Providers/Forms/QuotaForm/QuotaFormModalProvider'
import { Quota } from '../../../../utils/entities/quota'
import DisplacementDatePicker from '../../../../Components/institution/Quotas/shared/DisplacementDatePicker'
import YearlyQuotaChecker from '../../../../Providers/Forms/QuotaForm/YearlyQuotaChecker'
import IconButton from '../../../../Components/antd/Buttons/IconButton'

import '../../../../Components/institution/Quotas/assets/quotas.scss'

const Option = Select.Option

const mapDispatchToProps = state => ({ t: getTranslate(state.locale) })

const SchoolQuotaHeader = ({
  period, date, periodType, sector, selectedQuota,
  onPeriodChange, onDateChange, onIsYearlyChange,
  onQuotaChange, onRefresh, t
}) => {
  const { setFormHandler } = useContext(QuotaFormModalContext)

  const handleClick = useCallback(quota => setFormHandler(handler => ({ ...handler, data: quota, callback: onQuotaChange })), [setFormHandler, onQuotaChange])

  const handleEditClick = useCallback(() => handleClick(selectedQuota), [selectedQuota])

  const handleAddClick = useCallback(() => {
    handleClick(new Quota({
      sector,
      startDate: period?.startDate,
      endDate: period?.endDate,
      total: 0
    }))
  }, [sector, period])

  const periods = useMemo(() => TIME_PERIODS_TYPE.map((option, index) => (
    <Option key={index} value={index}>
      {t(option.text)}
    </Option>
  )), [t])

  const handlePeriodChange = useCallback(value => onPeriodChange(TIME_PERIODS_TYPE[value]), [onPeriodChange])

  return (
    <div className='flex-row mb-8'>
      <Select
        value={t(periodType.text ?? '')}
        onChange={handlePeriodChange}
      >
        {periods}
      </Select>

      <div className='h-spacing' />

      <IconButton
        icon={faPlus}
        disabled={!sector}
        text={t('quota_table_menu.buttons.add_quota')}
        onClick={handleAddClick}
      />

      <div className='h-spacing' />

      <Button
        disabled
        onClick={() => console.log('todo: Sélectionner les calques')}
      >
        {t('quota_table_menu.buttons.select_overlay')}
      </Button>

      <div className='h-spacing' />

      <IconButton
        icon={faEdit}
        disabled={!selectedQuota}
        text={t('quota_table_menu.buttons.edit_quota')}
        onClick={handleEditClick}
      />

      <div className='h-spacing' />

      <div>
        <span style={{ marginRight: '5px' }}>{t('quota_form.is_yearly')}</span>
        <YearlyQuotaChecker onCheck={onIsYearlyChange} />
      </div>
      <div className='flex-fill' />
      <DisplacementDatePicker
        date={date}
        timePeriod={periodType}
        onDateChange={onDateChange}
      />
    </div>
  )
}

export default connect(mapDispatchToProps)(SchoolQuotaHeader)
