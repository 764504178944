import React, { useContext, useMemo } from 'react'

import { getTranslate } from 'react-localize-redux'
import { connect } from 'react-redux'
import { InternshipRequestFile, getOriginalName, getType, getTypeColor } from '../../../../utils/entities/internshipRequestFile'
import { OrderBy } from '../../../../utils/constants'
import { Tag } from 'antd'
import { InternshipRequestFileModalContext } from '../../../../Providers/Forms/InternshipRequestFileModal'
import DataTable from '../../../../Components/antd/Tables/DataTable/DataTable'
import { DeleteAction } from '../../../../Components/antd/Tables/DataTable/utils/actions'

const mapStateToProps = state => ({ t: getTranslate(state.locale) })

const InternshipRequestFilesTable = ({ data, individualInternshipRequest, loading, metadata, parameters, onDelete, onParametersChange, reload, t }) => {
  const { setSelectedFile } = useContext(InternshipRequestFileModalContext)

  const columns = useMemo(() => [
    { title: t('data_table.column_title.name'), orderBy: OrderBy.ORIGINAL_NAME, key: getOriginalName },
    {
      title: t('data_table.column_title.type'),
      orderBy: OrderBy.TYPE,
      key: f => <Tag color={getTypeColor(f)}>{t('internship_request_file.types.' + getType(f).toLowerCase())}</Tag>
    }
  ], [t])

  const actions = useMemo(() => [new DeleteAction({ onDelete })], [onDelete])

  return (
    <DataTable
      actions={actions}
      columns={columns}
      data={data}
      loading={loading}
      metadata={metadata}
      parameters={parameters}
      onCreate={() => setSelectedFile({ data: new InternshipRequestFile({ individualInternshipRequest }), callback: reload })}
      onParametersChange={onParametersChange}
    />
  )
}

export default connect(mapStateToProps)(InternshipRequestFilesTable)
