import React, { useCallback, useContext, useMemo } from 'react'

import { getTranslate } from 'react-localize-redux'
import { connect } from 'react-redux'
import { notification } from 'antd'
import DragTable from '../../../../Components/antd/Tables/DragTable/DragTable'
import { ColorsPalette } from '../../../../config/colors'
import { Actions, Events, getXCoordinates, getYCoordinates } from '../../../../Components/antd/Tables/utils'
import { Quota, quotaFromResponse, areQuotaEquivalent } from '../../../../utils/entities/quota'
import { DATE_FORMAT_API } from '../../../../utils/constants'
import { QuotaFormModalContext } from '../../../../Providers/Forms/QuotaForm/QuotaFormModalProvider'
import { getUser } from '../../../../reducers/UserReducer'
import { onError, onSuccess } from '../../../../utils/apiHelper'
import { updateQuota } from '../../../../utils/api/quota'
import { isOptimistic } from '../../../../utils'
import { calcWeeksInMonth, MONTH_STRING_FORMAT, PeriodNames, WEEK_NUMBER_FORMAT } from '../../../../utils/momentjs'
import { isFunction } from 'lodash'

const mapStateToProps = state => ({ t: getTranslate(state.locale), user: getUser(state.getUser) })

const SchoolQuotasTable = ({ period, places, quotas, sector, selectedQuota, user, onChange, onSelect, t }) => {
  const { setFormHandler } = useContext(QuotaFormModalContext)

  const periods = useMemo(() => {
    const periods = []
    const step = period.step ?? PeriodNames.WEEK

    const { startDate, endDate } = period
    let currentDate = startDate.clone()

    while (currentDate < endDate) {
      periods.push({
        startDate: currentDate.clone().startOf(step),
        endDate: currentDate.clone().endOf(step),
        colspan: calcWeeksInMonth(currentDate),
        month: t(currentDate.format(MONTH_STRING_FORMAT)),
        week: t(currentDate.format(WEEK_NUMBER_FORMAT))
      })
      currentDate = currentDate.clone().add(1, step)
    }

    return periods
  }, [period, t])

  const data = useMemo(() => {
    const columnIndexes = Array(periods.length).fill(0)

    return quotas.map(q => {
      const x = getXCoordinates(q, periods)

      let max = 1
      for (let i = x.start; i <= x.end; i++) {
        if (columnIndexes[i] > max) {
          max = columnIndexes[i]
        }
      }

      const y = getYCoordinates(q, max)
      max = y.end + 1

      for (let i = x.start; i <= x.end; i++) {
        columnIndexes[i] = max
      }

      return {
        id: q.id,
        x: [x.start, x.end],
        y: [y.start, y.end],
        value: q.school.abbreviation || q.school.name,
        color: ColorsPalette.GREEN,
        quota: q
      }
    })
  }, [quotas, periods])

  const lines = useMemo(() => {
    const linesConfig = places.reduce((acc, quota) => {
      const id = quota.section ? quota.section.id : -1

      if (!acc[id]) {
        acc[id] = { id, places: 0, name: quota.section ? quota.section.abbreviation : '*' }
      }

      acc[id].places += quota.total

      return acc
    }, {})

    return Object.values(linesConfig).sort((a, b) => a.id - b.id)
  }, [places])

  const handleUpdateQuota = useCallback(quota => {
    if (areQuotaEquivalent(quota, selectedQuota)) return

    const action = Actions.UPDATE

    onChange(quota, action)

    updateQuota(user, quota)
      .then(json => {
        const responseData = quotaFromResponse(quota, json)

        onChange(responseData, action)
        onSuccess(t(`quota_form_modal.${action.toLowerCase()}.success`))
      })
      .catch(reason => {
        console.error(reason)

        onChange(quota, action)
        onError(t(`quota_form_modal.${action.toLowerCase()}.success`))
      })
  }, [t, user, selectedQuota, onChange])

  const handleAction = useCallback((quota, event) => {
    if (event === Events.DRAG_STOP) {
      handleUpdateQuota(quota)
    } else if (event === Events.CLICK) {
      isFunction(onSelect) && onSelect(!isOptimistic(quota) ? quota : null)
    } else if (event === Events.DOUBLE_CLICK || event === Events.LONG_CLICK) {
      if (isOptimistic(quota)) {
        quota.sector = sector
      }

      setFormHandler(handler => ({
        ...handler,
        data: quota,
        callback: onChange
      }))
    }
  }, [onSelect, sector, setFormHandler, handleUpdateQuota])

  const handleEvent = useCallback((data, event) => {
    let shouldDismissEvent = event === Events.DESELECT

    if (!shouldDismissEvent && !sector) {
      notification.warn({
        message: t('school_quotas_table.handle_event.no_sector_selected'),
        placement: 'bottomRight'
      })

      shouldDismissEvent = true
    }

    if (shouldDismissEvent) {
      if (isFunction(onSelect)) {
        onSelect(null)
      }
      return
    }

    const quota = data === null ? new Quota() : new Quota(data.quota)

    quota.startDate = periods[data.x[0]].startDate.format(DATE_FORMAT_API)
    quota.endDate = periods[data.x[1]].endDate.format(DATE_FORMAT_API)
    quota.total = data.y[1] - data.y[0] + 1

    handleAction(quota, event)
  }, [sector, periods, handleAction])

  return (
    <DragTable
      disabled={!sector}
      columns={periods}
      lines={lines}
      data={data}
      title={t('common.sections')}
      onEvent={handleEvent}
    />
  )
}

export default connect(mapStateToProps)(SchoolQuotasTable)
