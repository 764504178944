import { Switch } from "antd"
import { useCallback, useState } from "react"

import { getTranslate } from 'react-localize-redux'
import { connect } from 'react-redux'
import { isFunction } from "lodash"

const mapDispatchToProps = state => ({ t: getTranslate(state.locale) })

const YearlyQuotaChecker = ({ onCheck, t }) => {
  const [isYearly, setIsYearly] = useState(false)

  const toggleIsYearly = useCallback(() => {
    isFunction(onCheck) && onCheck(isYearly)
    setIsYearly(!isYearly)
  }, [isYearly, setIsYearly, onCheck])

  return (
    <Switch
      title={`${isYearly ? t('common.deactivate') : t('common.activate')} ${t('quota_form.is_yearly')}`}
      value={isYearly}
      checked={isYearly}
      onChange={toggleIsYearly}
    />
  )
}

export default connect(mapDispatchToProps)(YearlyQuotaChecker)