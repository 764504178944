import React, { useCallback, useMemo, useRef, useState } from 'react'

import ResizingDiv from './ResizingDiv'
import { connect } from 'react-redux'
import { getTranslate } from 'react-localize-redux'
import { isFunction, debounce } from 'lodash'
import { Events, getDisplacement, getPixelIntVal } from '../utils'

import '../../../../assets/drag-table.scss'

const mapStateToProps = state => ({ t: getTranslate(state.locale) })

const CONFIG = {
  cell: { style: { maxWidth: '50px', minWidth: '50px', height: '25px' } },
  title: { style: { textAlign: 'center', height: '50px', minWidth: '150px' } },
  titleCell: { style: { height: '25px', minWidth: '150px' } },
  offset: { left: 3, top: 1 }
}

const TABLE_STYLE = { maxHeight: '100%', position: 'relative' }

const DragTable = ({ actions = [], columns = [], config = CONFIG, data = [], disabled, lines, title = '', onEvent, t }) => {
  const [selectedData, setSelectedData] = useState(null)
  const [linesOffset, setLinesOffset] = useState({})
  const [scrollOffset, setScrollOffset] = useState({ left: 0, top: 0 })
  const [area, setArea] = useState({ start: null, end: null })
  const [mode, setMode] = useState(null)

  const tableRef = useRef(null)
  const longPressRef = useRef(null)
  const scrollRef = useRef(null)

  const maxHeight = useMemo(() => lines.reduce((acc, l) => acc + l.places + (linesOffset[l.name] ?? 0), lines.length === 0 ? 1 : 0), [lines, linesOffset])
  const cellConf = useMemo(() => config.cell ?? CONFIG.cell, [config])
  const titleConf = useMemo(() => config.title ?? CONFIG.title, [config])
  const titleCell = useMemo(() => ({ ...CONFIG.titleCell, ...(config.titleCell ?? {}) }), [config.titleCell])
  const boundaries = useMemo(() => ({ x: [3, columns.length - 1], y: [2, maxHeight] }), [maxHeight, columns.length])
  const offset = useMemo(() => ({
    left: { size: getPixelIntVal(cellConf.style.minWidth), value: config?.left ?? CONFIG.offset.left },
    top: { size: getPixelIntVal(cellConf.style.height), value: config?.top ?? CONFIG.offset.top }
  }), [config.offset, cellConf.style])
  const divConfig = useMemo(() => ({ boundaries, displacement: { x: offset.left.size, y: offset.top.size }, scrollOffset }), [boundaries, offset])

  const handleEvent = useCallback((data, event) => {
    if (isFunction(onEvent)) {
      onEvent(data, event)
    }
  }, [onEvent])

  const handleMaxIndexOverflow = useCallback(() => {
    setLinesOffset({ ...linesOffset, [lines[lines.length - 1].name]: (linesOffset[lines[lines.length - 1].name] ?? 0) + 1 })
  }, [linesOffset, setLinesOffset])

  const handleDragStop = useCallback(data => {
    setSelectedData(data)
    handleEvent(data, Events.DRAG_STOP)
  }, [onEvent, handleEvent])

  const handleCellDoubleClick = useCallback(cell => {
    setSelectedData(null)
    handleEvent(cell, Events.DOUBLE_CLICK)
  }, [handleEvent, setSelectedData])

  const handleDeselect = useCallback(() => {
    setSelectedData(null)
    handleEvent(null, Events.DESELECT)
  }, [handleEvent, setSelectedData])

  const handleDataClick = useCallback(data => {
    setSelectedData(data)
    handleEvent(data, Events.CLICK)
  }, [handleEvent, setSelectedData])

  const handleDataDoubleClick = useCallback(data => handleEvent(data, Events.DOUBLE_CLICK), [handleEvent])

  const handleOnMouseDown = e => {
    e.preventDefault()
    e.stopPropagation()
    const startCell = getCoordinates({ x: e.clientX, y: e.clientY })

    setArea({ start: startCell, end: startCell })

    longPressRef.current = debounce(() => setMode(Events.LONG_CLICK), 200)
    longPressRef.current()
  }

  const handleOnMouseUp = e => {
    const startCell = area.start
    const endCell = area.end

    if (mode === Events.LONG_CLICK) {
      setMode(null)

      handleEvent({ x: [startCell.x, endCell.x], y: [startCell.y, endCell.y], data: null }, Events.LONG_CLICK)
    } else {
      const selectedData = getQuotaByCoordinates(startCell)

      longPressRef.current.cancel()
      setSelectedData(null)
      handleEvent({ x: [startCell.x, startCell.x], y: [startCell.y, startCell.y], data: selectedData }, Events.CLICK)
    }

    setArea({ start: null, end: null })
  }

  const getCoordinates = ({ x, y }) => {
    const table = tableRef.current.getBoundingClientRect()

    return {
      x: Math.floor((x - table.left) / offset.left.size),
      y: Math.floor((y - table.top) / offset.top.size) - 1
    }
  }

  const getQuotaByCoordinates = ({ x, y }) => {
    return data.find(d => d.x[0] <= x && x <= d.x[1] && d.y[0] <= y && y <= d.y[1]) ?? null
  }

  const logMouseMouvement = e => {
    if (mode === Events.LONG_CLICK && area.start !== null) {
      const endCell = getCoordinates({ x: e.clientX, y: e.clientY })

      if (area.end === null || endCell.x !== area.end.x || endCell.y !== area.end.y) {
        setArea({ ...area, end: endCell })
      }
    }
  }

  const getHighlight = ({ x, y }) => {
    const style = { borderBottom: '1px solid grey', borderRight: '1px solid grey', borderTop: '1px solid grey', borderLeft: '1px solid grey' }

    if (area.start !== null && area.end !== null && mode === Events.LONG_CLICK) {
      if (area.start.x <= x && x <= area.end.x && area.start.y <= y && y <= area.end.y) {
        delete style.border
        style.backgroundColor = 'rgba(0, 0, 0, 0.1)'

        if (x === area.start.x) {
          style.borderLeft = '1px solid black'
        }

        if (x === area.end.x) {
          style.borderRight = '1px solid black'
        }

        if (y === area.start.y) {
          style.borderTop = '1px solid black'
        }

        if (y === area.end.y) {
          style.borderBottom = '1px solid black'
        }
      } else if (x === area.start.x - 1 && y <= area.end.y && y >= area.start.y) {
        style.borderRight = '1px solid black'
      } else if (y === area.start.y - 1 && x <= area.end.x && x >= area.start.x) {
        style.borderBottom = '1px solid black'
      }
    }

    return style
  }

  const handleScroll = e => {
    e.stopPropagation()

    if (isFunction(scrollRef.current)) {
      scrollRef.current.cancel()
    }

    const offsetTop = getDisplacement(0, e.target.scrollTop, offset.top.size)
    const offsetLeft = getDisplacement(0, e.target.scrollLeft, offset.left.size)

    longPressRef.current = debounce(() => setScrollOffset({ left: offsetLeft, top: offsetTop }), 300)
    longPressRef.current()
  }

  let prevMonth = ''

  return (
    <div className='draggable-table-container'>
      <div className='table-container w-100'>
        <div className='table-content' style={TABLE_STYLE} onScroll={handleScroll}>
          <table>
            <thead style={{ backgroundColor: 'whitesmoke' }}>
              <tr>
                <td className='drag-table-title' style={titleConf.style}> {title} </td>
              </tr>
            </thead>
            <tbody>
              {lines.map((line, index) => (
                <tr key={`line_${line.name}_${index}`}>
                  <td
                    className='drag-table-title'
                    style={{ ...titleCell.style, height: ((line.places + (linesOffset[line.name] ?? 0)) * getPixelIntVal(titleCell.style.height)) + 'px' }}
                  >
                    {line.name}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <table ref={tableRef} onMouseDown={handleOnMouseDown} onMouseUp={handleOnMouseUp} onMouseMove={logMouseMouvement}>
            <thead style={{ backgroundColor: 'whitesmoke' }}>
              <tr>
                {columns.map((c, index) => {
                  const className = selectedData && selectedData.x[0] <= index && index <= selectedData.x[1] ? 'highlighted' : ''
                  const monthTitle = c.month === prevMonth ? '' : c.month

                  prevMonth = c.month

                  return (
                    <th key={`col_${index}`} className={className} style={cellConf.style}> {monthTitle} </th>
                  )
                })}
              </tr>
              <tr>
                {columns.map((c, index) => {
                  const className = selectedData && selectedData.x[0] <= index && index <= selectedData.x[1] ? 'highlighted' : ''

                  return (
                    <th key={`col_${index}`} className={className} style={cellConf.style}> {c.week} </th>
                  )
                })}
              </tr>
            </thead>
            <tbody>
              {lines.reduce((acc, line) => {
                for (let i = 0; i < line.places + (linesOffset[line.name] ?? 0); i++) {
                  acc.push((
                    <tr key={`line_${line.id}_${i}`}>
                      {columns.map((c, index) => {
                        const localStyle = getHighlight({ x: index, y: acc.length + offset.top.value })

                        return (
                          <td
                            key={`cell_${line.id}_${i}_${index}`}
                            style={{ ...cellConf.style, ...localStyle }}
                            onDoubleClick={() => handleCellDoubleClick({ x: [index, index], y: [acc.length, acc.length] })}
                          />
                        )
                      })}
                    </tr>
                  ))
                }

                return acc
              }, [])}
            </tbody>
          </table>
          {data.map((d, index) => (
            <ResizingDiv
              key={`key_${index}`}
              config={divConfig}
              content={d.value}
              data={{ ...d }}
              selected={selectedData?.id === d.id}
              onDeselect={handleDeselect}
              onClick={handleDataClick}
              onDoubleClick={handleDataDoubleClick}
              onDragStop={handleDragStop}
              onMaxIndexOverflow={handleMaxIndexOverflow}
            />
          ))}
        </div>
      </div>
    </div>
  )
}

export default connect(mapStateToProps)(DragTable)
