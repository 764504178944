import React, { useCallback, useContext, useState } from 'react'

import Settings from './Settings'
import SchoolQuotasTable from './SchoolQuotasTable'
import { QuotasContext } from '../../../../Providers/Quotas/QuotasProvider'
import { Actions } from '../../../../Components/antd/Tables/utils'
import { TIME_PERIODS_TYPE } from '../../../../utils/timePeriods'
import SchoolQuotasHeader from './SchoolQuotasHeader'
import QuotaFormModalProvider from '../../../../Providers/Forms/QuotaForm/QuotaFormModalProvider'

import '../../../../assets/quota-allocation.scss'

const MOCK_PLACES = [
  { total: 3, section: null },
  { total: 3, section: null },
  { total: 3, section: { abbreviation: 'BIRSG', id: 10 } },
  { total: 2, section: { abbreviation: 'SFE', id: 5 } }
]

const SchoolQuotasView = () => {
  const { date, setDate, period, periodType, setPeriodType } = useContext(QuotasContext)

  const [sector, setSector] = useState(null)
  const [school, setSchool] = useState(null)
  const [isYearly, setIsYearly] = useState(false)
  const [selectedQuota, setSelectedQuota] = useState(null)
  const [places, setPlaces] = useState(MOCK_PLACES)
  const [sectorQuotas, setSectorQuotas] = useState([])

  const handleQuotaChange = useCallback((quota, action = Actions.CREATE) => {
    if (action === Actions.CREATE) {
      setSectorQuotas(quotas => quotas.concat(quota))
    } else if (action === Actions.UPDATE) {
      quota.isNewQuota
        ? setSectorQuotas(quotas => quotas.map(q => q.id === -1 ? quota : q))
        : setSectorQuotas(quotas => quotas.map(q => q.id === quota.id ? quota : q))
    } else if (action === Actions.DELETE) {
      setSectorQuotas(quotas => quotas.filter(q => q.id !== quota.id))
    }
  }, [setSectorQuotas])

  const handleDateChange = useCallback(date => {
    setSelectedQuota(null)
    setDate(date)
  }, [setSelectedQuota, setDate])

  const handlePeriodChange = useCallback(period => {
    setSelectedQuota(null)
    setPeriodType(period)
  }, [setSelectedQuota, setPeriodType])

  return (
    <div className='school-quotas-container flex-row h-100 w-100'>
      <QuotaFormModalProvider>
        <div className='select-container w-15 mr-8'>
          <Settings onSelectSector={setSector} onSelectSchool={setSchool} />
        </div>
        <div className='table-container w-85'>
          <SchoolQuotasHeader
            sector={sector}
            date={date}
            period={period}
            periodType={periodType}
            defaultPeriod={TIME_PERIODS_TYPE[1]}
            selectedQuota={selectedQuota}
            onDateChange={handleDateChange}
            onPeriodChange={handlePeriodChange}
            onIsYearlyChange={setIsYearly}
            onQuotaChange={handleQuotaChange}
          />
          <SchoolQuotasTable
            sector={sector}
            period={period}
            places={places}
            quotas={sectorQuotas}
            selectedQuota={selectedQuota}
            onSelect={setSelectedQuota}
            onChange={handleQuotaChange}
          />
        </div>
      </QuotaFormModalProvider>
    </div>
  )
}

export default SchoolQuotasView
