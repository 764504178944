import React, { useContext, useMemo } from 'react'

import Cookies from 'js-cookie'
import { OrderBy } from '../../../../utils/constants'
import { getTranslate } from 'react-localize-redux'
import { connect } from 'react-redux'
import { getColor, getManagersLabel, getName, HospitalDepartment } from '../../../../utils/entities/hospitalDepartment'
import { HospitalDepartmentModalContext } from '../../../../Providers/Forms/HospitalDepartmentModal'
import { HospitalDepartmentSectorsDrawerContext } from '../../../../Providers/Drawer/HospitalDepartmentSectorsDrawer'
import { Tag } from 'antd'
import { getLuminanceFromColorCode } from '../../../../utils/colors'
import CompactedList from '../../../../Components/shared/CompactedList'
import { HospitalDepartmentDrawerContext } from './HospitalDepartmentDrawer'
import { ConsultUsers, DeleteAction, DisplayDepartmentSectors, EditHospitalDepartment } from '../../../../Components/antd/Tables/DataTable/utils/actions'
import DataTable from '../../../../Components/antd/Tables/DataTable/DataTable'

const DEFAULT_COLOR = '#A3A3A4'

const mapStateToProps = state => ({ t: getTranslate(state.locale) })

const HospitalDepartmentsTable = ({ data, institution, loading, metadata, parameters, onDelete, onParametersChange, onReload, onLinkChange, t, setOpenUserDrawer }) => {
  const { setSelectedHospitalDepartment } = useContext(HospitalDepartmentModalContext)
  const hdd = useContext(HospitalDepartmentSectorsDrawerContext)
  const hddc = useContext(HospitalDepartmentDrawerContext)

  const maxHeight = useMemo(() => `calc(100vh - ${Cookies.get('switch_user_id') ? '230px' : '189px'})`, [Cookies.get('switch_user_id')])
  const columns = useMemo(() => [
    { title: t('data_table.column_title.name'), orderBy: OrderBy.NAME, key: getName },
    {
      title: t('data_table.column_title.managers'),
      orderBy: OrderBy.MANAGER,
      key: h => (
        <CompactedList data={getManagersLabel(h).split(',')} />
      ),
      style: { maxWidth: '200px' }
    },
    {
      title: t('data_table.column_title.color'),
      key: h => (
        <Tag color={getColor(h) ?? DEFAULT_COLOR} style={{ color: getLuminanceFromColorCode(getColor(h) ?? DEFAULT_COLOR) }}>
          {t(getColor(h) ?? DEFAULT_COLOR)}
        </Tag>
      )
    }
  ], [t])
  const actions = useMemo(() => [
    new EditHospitalDepartment({ onClick: h => setSelectedHospitalDepartment({ data: h, callback: onReload }) }).build(),
    new ConsultUsers({ onClick: h => hddc.setSelectedHospitalDepartment({ data: h }) }).build(),
    new DisplayDepartmentSectors({ onClick: h => hdd.setSelectedHospitalDepartment({ data: h, callback: onLinkChange }) }).build(),
    new DeleteAction({ onDelete })
  ], [onDelete, onReload, setSelectedHospitalDepartment, hdd.setSelectedHospitalDepartment, hddc.setSelectedHospitalDepartment])
  const externalActions = useMemo(() => [], [])

  return (
    <div style={{ height: maxHeight }}>
      <DataTable
        actions={actions}
        columns={columns}
        data={data}
        externalActions={externalActions}
        loading={loading}
        metadata={metadata}
        parameters={parameters}
        onCreate={() => setSelectedHospitalDepartment({ data: new HospitalDepartment({ institution }), callback: onReload })}
        onParametersChange={onParametersChange}
      />
    </div>
  )
}

export default connect(mapStateToProps)(HospitalDepartmentsTable)
