import React, { useCallback, useEffect, useState } from 'react'

import { OrderBy } from '../../../utils/constants'
import { Metadata, retrieveMetadata } from '../../../utils/http'
import { getUser } from '../../../reducers/UserReducer'
import { getTranslate } from 'react-localize-redux'
import { addExternalContact, deleteExternalContact, getExternalContacts, updateExternalContact } from '../../../utils/api/externalContact'
import { onSuccess } from '../../../utils/apiHelper'
import ExternalContactFormModal from '../../../HOC/Forms/ExternalContactFormModal'
import { debounce } from 'lodash'
import ExternalContactsTable from './ExternalContactsTable'
import { connect } from 'react-redux'
import { TableParameters } from '../../../utils/entities/tableParameters'
import { TableActions } from '../../antd/Tables/DataTable/utils/actions'

const DEFAULT_DATA = { data: [], meta: new Metadata({ pageSize: 20 }) }
const DEFAULT_PARAMETERS = { orderBy: OrderBy.LASTNAME, pageSize: 20 }

const mapStateToProps = state => {
  return {
    t: getTranslate(state.locale),
    user: getUser(state.getUser)
  }
}

const ExternalContactsView = ({ user, onTriggerAction, t }) => {
  const [data, setData] = useState(DEFAULT_DATA)
  const [parameters, setParameters] = useState(new TableParameters(DEFAULT_PARAMETERS))
  const [loading, setLoading] = useState(true)

  const fetchData = useCallback((user, parameters) => {
    setLoading(true)

    getExternalContacts(user, parameters.toQueryParametersOld()).then(json => {
      if (json?.data || json?.meta) {
        const data = {}

        data.data = json?.data ?? []
        data.meta = json?.meta ? retrieveMetadata(json.meta) : new Metadata()

        setData(data)
      }

      setLoading(false)
    })
  }, [setLoading, setData])

  const createExternalContact = useCallback(data => {
    addExternalContact(data, user).then(json => {
      onSuccess(t('The external contact has been created successfully'))
      fetchData(user, parameters)
    })
  }, [user, t, onSuccess, fetchData, parameters])

  const editExternalContact = useCallback(data => {
    updateExternalContact(data, user).then(json => {
      onSuccess(t('The external contact has been updated successfully'))
      fetchData(user, parameters)
    })
  }, [user, t, onSuccess, fetchData, parameters])

  const removeExternalContact = useCallback(data => {
    deleteExternalContact(user, data).then(json => {
      onSuccess(t('The external contact has been deleted successfully'))
      fetchData(user, parameters)
    })
  }, [user, t, onSuccess, fetchData, parameters])

  const handleActionTrigger = useCallback((entity, action) => {
    if (action === TableActions.CREATE_EC) {
      createExternalContact(entity)
    } if (action === TableActions.EDIT_EC) {
      editExternalContact(entity)
    } if (action === TableActions.DELETE_EC) {
      removeExternalContact(entity)
    } else if (typeof onTriggerAction === 'function') {
      onTriggerAction(entity, action)
    }
  }, [editExternalContact])

  useEffect(() => {
    const fetch = debounce(() => fetchData(user, parameters), 300)

    fetch()

    return () => {
      fetch.cancel()
    }
  }, [user.id, parameters, fetchData])

  return (
    <ExternalContactFormModal
      useSelector
      onTriggerAction={handleActionTrigger}
      onSave={() => fetchData(user, parameters)}
    >
      <ExternalContactsTable
        data={data.data}
        loading={loading}
        metadata={data.meta}
        parameters={parameters}
        onParametersChange={setParameters}
      />
    </ExternalContactFormModal>
  )
}

export default connect(mapStateToProps)(ExternalContactsView)
