import React, { useContext, useMemo } from 'react'

import { InstitutionContext } from '../../../../Providers/InstitutionProvider'
import { SchoolContext } from '../../../../Providers/SchoolProvider'
import { isNotNullObject } from '../../../../utils'
import EntitiesSelector from './EntitiesSelector'

const SchoolsSelector = ({ disabled, multiple, style = {}, values, onChange }) => {
  const ic = useContext(InstitutionContext)
  const sc = useContext(SchoolContext)

  const schools = useMemo(() => {
    let schools = []

    if (isNotNullObject(ic)) {
      schools = ic.schools
    } else if (isNotNullObject(sc)) {
      schools = [sc.school]
    }

    return schools
  }, [ic, sc])

  const schoolsOptions = useMemo(() => schools.map(school => ({
    label: school.abbreviation ?? school.name,
    value: school.id,
    tooltip: school.name,
    entity: school
  })), [schools])

  return (
    <EntitiesSelector
      disabled={disabled}
      entityOptions={schoolsOptions}
      multiple={multiple}
      showSearch
      style={style}
      values={values}
      onChange={onChange}
    />
  )
}

export default SchoolsSelector
