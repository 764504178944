import React, { useState, useEffect, useRef, useMemo, useCallback } from 'react'

import { getTranslate } from 'react-localize-redux'
import { getUser } from '../../../reducers/UserReducer'
import { connect } from 'react-redux'
import { createSchoolPermission, deleteSchoolPermission, getSchoolPermissions, modifySchoolPermission } from '../../../utils/api/schoolPermission'
import { SchoolPermission } from '../../../utils/entities/SchoolPermission'
import { Modal, Tag } from 'antd'
import { faEdit } from '@fortawesome/pro-solid-svg-icons'
import SchoolPermissionForm from '../../shared/Forms/SchoolPermissionForm'
import { HttpStatuses, Metadata, retrieveMetadata } from '../../../utils/http'
import { onError, onSuccess } from '../../../utils/apiHelper'
import { TableParameters } from '../../../utils/entities/tableParameters'
import { Action, DeleteAction } from '../../antd/Tables/DataTable/utils/actions'
import DataTable from '../../antd/Tables/DataTable/DataTable'

const mapStateToProps = state => ({ t: getTranslate(state.locale), user: getUser(state.getUser) })

const SchoolPermissionTable = ({ type, user, t }) => {
  const [filters, setFilters] = useState(new TableParameters({ order: { by: 'schoolName' }, pageSize: 20 }))
  const [data, setData] = useState({ data: [], meta: new Metadata({ pageSize: 20 }) })
  const [createdData, setCreatedData] = useState(null)
  const [editedData, setEditedData] = useState(null)
  const [loading, setLoading] = useState(true)

  const fetchPermissions = useCallback(() => {
    setLoading(true)

    const parameters = filters.toQueryParametersOld()

    parameters.type = type

    getSchoolPermissions(user, parameters).then(json => {
      if (json?.data || json?.meta) {
        const data = {}

        data.data = json?.data ?? []
        data.meta = json?.meta ? retrieveMetadata(json.meta) : new Metadata()

        setData(data)
      }

      setLoading(false)
    })
  }, [type, user, filters, setLoading])

  const createPermission = useCallback(data => {
    setCreatedData(null)

    createSchoolPermission(
      new SchoolPermission({ ...data, institution: user.institutions[0], type }),
      user
    ).then(json => {
      onSuccess(t('The permission has been created successfully'))
      fetchPermissions(user, filters)
    }).catch(response => {
      let message = 'The permission could not be created correctly' + '. Error code: ' + response.status

      if (response.status === HttpStatuses.CONFLICT) {
        message = 'A permission of this type already exist'
      }

      onError(t(message))
    })
  }, [user, t, onSuccess, onError, fetchPermissions, setCreatedData, createSchoolPermission])

  const deletePermission = useCallback(data => {
    deleteSchoolPermission(data, user).then(json => {
      onSuccess(t('The permission has been deleted successfully'))
      fetchPermissions()
    }).catch(response => {
      onError(t('The permission could not be deleted correctly') + '. Error code: ' + response.status)
    })
  }, [user, onSuccess, onError, fetchPermissions, deleteSchoolPermission, t])

  const updatePermission = useCallback(data => {
    setEditedData(null)

    modifySchoolPermission(
      new SchoolPermission({ ...data }),
      user
    ).then(json => {
      onSuccess(t('The permission has been updated successfully'))
      fetchPermissions()
    }).catch(response => {
      let message = 'The permission could not be updated correctly' + '. Error code: ' + response.status

      if (response.status === HttpStatuses.CONFLICT) {
        message = 'A permission of this type already exist'
      }

      onError(t(message))
    })
  }, [user, onSuccess, onError, fetchPermissions, modifySchoolPermission, setEditedData, t])

  const columns = useMemo(() => [
    { title: t('common.school'), key: d => d.school === null ? '*' : d.school.name },
    { title: t('common.section'), key: d => d.sectionToYear === null ? '*' : `${d.sectionToYear.sectionAbbreviation} (${d.sectionToYear.schoolYearName})` },
    { title: t('Care unit'), key: d => d.sector === null ? '*' : d.sector.name },
    {
      title: t('Status'),
      key: d => {
        return (
          <Tag
            color={d.permissionEnabled ? 'green' : 'red'}
            style={{ cursor: 'pointer' }}
            onClick={() => updatePermission({ ...d, permissionEnabled: !d.permissionEnabled })}
          >
            {t(d.permissionEnabled ? 'Granted' : 'modules_settings.refused')}
          </Tag>
        )
      }
    }
  ], [t])
  const actions = useMemo(() => [
    new Action({ title: 'Edit', icon: faEdit, onClick: setEditedData }),
    new DeleteAction({ onDelete: deletePermission })
  ], [setEditedData, faEdit, deletePermission])

  const timer = useRef(null)

  useEffect(() => {
    if (user) {
      clearTimeout(timer.current)
      timer.current = setTimeout(() => fetchPermissions(user, filters), 300)
    }
  }, [user.institutionGroup, filters, timer])

  return (
    <>
      <DataTable
        actions={actions}
        columns={columns}
        data={data.data}
        parameters={filters}
        footerStyle={{ paddingRight: '110px' }}
        loading={loading}
        metadata={data.meta}
        onCreate={() => setCreatedData({ school: null, sectionToYear: null, sector: null, permissionEnabled: true })}
        onParametersChange={setFilters}
      />
      <Modal
        width={600}
        title={<h3 style={{ textAlign: 'center', margin: '0px' }}> {t('Modify the permission')} </h3>}
        visible={!!editedData}
        footer={false}
        onCancel={() => setEditedData(null)}
        destroyOnClose
      >
        <SchoolPermissionForm
          data={editedData}
          onSubmit={updatePermission}
        />
      </Modal>
      <Modal
        width={600}
        title={<h3 style={{ textAlign: 'center', margin: '0px' }}> {t('Add a permission')} </h3>}
        visible={!!createdData}
        footer={false}
        onCancel={() => setCreatedData(null)}
        destroyOnClose
      >
        <SchoolPermissionForm
          data={createdData}
          onSubmit={createPermission}
        />
      </Modal>
    </>
  )
}

export default connect(mapStateToProps)(SchoolPermissionTable)
