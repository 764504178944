import UserReducer from '../../reducers/UserReducer'
import { HttpMethods, makeQueryString, makeQueryStringFromObject } from '../apiHelper'
import { ExportTypes, LanguageTags } from '../constants'
import { requestWithPromise } from '../index'
import apiClient from "./apiClient";

export const getAllSectors = (user, paramaters = {}) => {
  return requestWithPromise(`/api/sectors${makeQueryString(paramaters)}`, HttpMethods.GET, null, user)
}

export const getSectorDocuments = (user, sector) => {
  return requestWithPromise(
    `/api/sectors/${sector.id}/documents`,
    HttpMethods.GET,
    null,
    user
  )
}

export const getPlaces = (sector, user, parameters = {}) => {
  return requestWithPromise(
    `/quota/sectors/${sector.id}${makeQueryStringFromObject(parameters)}`,
    HttpMethods.GET,
    null,
    user
  )
}

export const fetchSectorScore = (user, sector, endDate, startDate, additionalData) => {
  return requestWithPromise(
    `/sector/${sector.id}/evaluation-internships/score?end-date=${endDate}&start-date=${startDate}&additional-data=${additionalData}`,
    HttpMethods.GET,
    null,
    user
  ).then(json => {
    return json?.data ?? {}
  })
}

export const getSectorPresets = (user, sector) => {
  return requestWithPromise(
    `/api/sectors/${sector.id}/shift-presets`,
    HttpMethods.GET,
    null,
    user
  )
}

export const getInternshipsBySector = (user, sector) => {
  return requestWithPromise(
    `/api/sectors/${sector.id}/internships`,
    HttpMethods.GET,
    null,
    user
  )
}

export const createSector = (user, sector) => {
  return requestWithPromise(
    '/api/sectors',
    HttpMethods.POST,
    sector.toRequestBody(),
    user
  )
}

/** @deprecated 2024-06-06 */
export const createSectorOld = (user, sector) => {
  return requestWithPromise(
    '/api/Sector',
    HttpMethods.POST,
    sector,
    user
  )
}

export const updateSector = (user, sector) => {
  return requestWithPromise(
    `/api/sectors/${sector.id}`,
    HttpMethods.PATCH,
    sector.toRequestBody(),
    user
  )
}

/** @deprecated 2024-06-06 */
export const updateSectorOld = (user, sector, body) => {
  return requestWithPromise(
    `/api/Sector/${sector.id}`,
    HttpMethods.PATCH,
    body,
    user
  )
}

export const archiveSector = (user, sector, archive = true) => {
  return requestWithPromise(
    `/sector/archive/${sector.id}`,
    HttpMethods.PATCH,
    { toArchive: archive },
    user
  )
}

export const deleteSector = (user, sector) => {
  return requestWithPromise(
    `/api/sector/${sector.id}`,
    HttpMethods.DELETE,
    null,
    UserReducer
  )
}

export const switchSectorInstitution = (user, sector, institution) => {
  return requestWithPromise(
    `/sectors/${sector.id}/transfer`,
    HttpMethods.PATCH,
    { targetInstitution: institution.id },
    user
  )
}

export const assignManagedBy = (user, sector, idsManagedBy) => {
  return requestWithPromise(
    `/api/sectors/${sector.id}/users`,
    HttpMethods.PATCH,
    { users: idsManagedBy },
    user
  )
}

export const addSectorStudentPathways = (user, sector, pathways = []) => {
  return requestWithPromise(
    `/api/sectors/${sector.id}/student-pathways`,
    HttpMethods.POST,
    pathways,
    user
  )
}

export const getSectorStudentPathways = (user, sector) => {
  return requestWithPromise(
    `/api/sectors/${sector.id}/student-pathways`,
    HttpMethods.GET,
    null,
    user
  )
}

export const removeSectorStudentPathways = (user, sector, pathways) => {
  return requestWithPromise(
    `/api/sectors/${sector.id}/student-pathways${makeQueryString({ ids: pathways })}`,
    HttpMethods.DELETE,
    null,
    user
  )
}

export const addSectorActTypes = (user, sector, actTypes = []) => {
  return requestWithPromise(
    `/api/sectors/${sector.id}/act-types`,
    HttpMethods.POST,
    { ids: actTypes },
    user
  )
}

export const getSectorActTypes = (user, sector) => {
  return requestWithPromise(
    `/api/sectors/${sector.id}/act-types`,
    HttpMethods.GET,
    null,
    user
  )
}

export const removeSectorActTypes = (user, sector, actTypes) => {
  return requestWithPromise(
    `/api/sectors/${sector.id}/act-types${makeQueryString({ ids: actTypes })}`,
    HttpMethods.DELETE,
    null,
    user
  )
}

export const exportSectors = (user, parameters = { language: LanguageTags.EN, type: ExportTypes.CSV }) => {
  return requestWithPromise(
    '/export/sectors',
    HttpMethods.POST,
    parameters,
    user
  )
}

export const getSector = (user, sector) => {
  return requestWithPromise(
    `/api/sectors/${sector.id}`,
    HttpMethods.GET,
    null,
    user
  )
}

export const getSectorSections = (user, sector) => apiClient
  .get(user, `api/sectors/${sector.id}/sections`)