import moment from 'moment'
import { store } from '../../..'
import { isObject, objectToArray } from '../../../utils'
import { getPresetTextColor } from '../../../utils/colors'
import { DATE_FORMAT_API, Contexts, SchoolRoles } from '../../../utils/constants'
import { makeNewShift } from '../../../utils/entities/entityFactory'

export const buildBooleanMap = ids => {
  const map = {}

  ids.forEach(id => {
    map[id] = true
  })

  return map
}

export const checkFullOverlap = (items, ids) => {
  let count = 0

  items.forEach(i => {
    if (ids.includes(i.id)) {
      count++
    }
  })

  return count === items.length && count !== 0
}

const getIndexKey = (rowIndex, columnIndex) => {
  return rowIndex + '-' + columnIndex
}

const getSectorPresets = (institutionId, sectorId, presets) => {
  let sectorPresets = []

  if (presets[institutionId] && presets[institutionId][sectorId]) {
    sectorPresets = objectToArray(presets[institutionId][sectorId]).map(preset => {
      preset.textColor = getPresetTextColor(preset)

      return preset
    })
  }

  return orderPresetsByStartTimeAndEndTime(sectorPresets)
}

const orderPresetsByStartTimeAndEndTime = presets => {
  return presets.sort((a, b) => {
    const startTimeDiff = moment(a.startTime, 'HH:mm:ss').diff(moment(b.startTime, 'HH:mm:ss'))

    if (startTimeDiff !== 0) {
      return startTimeDiff
    }

    const firstEndTime = +a.startTime.split(':')[0] < 12
      ? moment(a.endTime, 'HH:mm:ss').add(1, 'days')
      : moment(a.endTime, 'HH:mm:ss')
    const secundEndTime = +b.startTime.split(':')[0] < 12
      ? moment(b.endTime, 'HH:mm:ss').add(1, 'days')
      : moment(b.endTime, 'HH:mm:ss')
    const endtimeDiff = firstEndTime.diff(secundEndTime)

    return endtimeDiff
  })
}

const getShiftByDayAndInternship = (internship, date) => {
  const shifts = store.getState().shifts

  return shifts[internship.id]
    ? shifts[internship.id].find(shift => {
      return moment(shift.startDate, DATE_FORMAT_API).diff(date, 'day') === 0
    })
    : null
}

const makeUrlBody = (periodCode, internshipId, shift, eventCodeType, startTime, endTime, isDesiderata) => {
  return {
    periodCode: periodCode ?? '',
    internship: internshipId,
    shift: shift.id ?? undefined,
    eventCodeType,
    startTime,
    endTime,
    isDesiderata
  }
}

const getIsoTime = displayTime => {
  return displayTime ? displayTime + ':00' : ''
}

const getDisplayTime = isoTime => {
  return isoTime ? isoTime.slice(0, 5) : ''
}

const canOnlyEditEventCode = (user, internship) => {
  if (user.context === Contexts.SCHOOL) {
    let canEditSchedule = false

    if (user.canSchoolEditSchedulesByInstitution[internship.institution?.id ?? -1]) {
      let sectionId = -1
      const tree = user.canSchoolEditSchedulesByInstitution[internship.institution.id]
      const sectorId = tree[internship.sector.id] !== undefined ? internship.sector.id : -1

      if (isObject(internship.internshipYear)) {
        sectionId = tree[sectorId][internship.internshipYear.id] !== undefined ? internship.internshipYear.id : -1
      }

      canEditSchedule = tree[sectorId][sectionId]
    }

    return !canEditSchedule
  }

  if (user.roles.includes(SchoolRoles.VALIDATOR)) {
    return true
  }

  return false
}

const createShiftOrConfigureIt = (shift, internshipId, startDate, endDate) => {
  if (shift) {
    return setShiftDateAsMoment(shift)
  }

  return makeNewShift(
    internshipId,
    startDate,
    endDate
  )
}

const setShiftDateAsMoment = shift => {
  return {
    ...shift,
    startDate: moment(shift.startDate),
    endDate: moment(shift.endDate)
  }
}

export {
  getIndexKey,
  getSectorPresets,
  orderPresetsByStartTimeAndEndTime,
  getShiftByDayAndInternship,
  makeUrlBody,
  getIsoTime,
  getDisplayTime,
  canOnlyEditEventCode,
  createShiftOrConfigureIt,
  setShiftDateAsMoment
}
