import React, { useContext, useMemo } from 'react'

import { GlobalContext } from '../../../../Providers/GlobalProvider'
import { formatAsArray, getIdOrNull } from '../../../../utils'
import EntitiesSelector from './EntitiesSelector'

const SectionToYearSelector = ({ disabled, multiple, style = {}, section, values, placeholderOption = null, onChange }) => {
  const { sectionToYearsBySection } = useContext(GlobalContext)

  const sectionToYearOptions = useMemo(() => formatAsArray(sectionToYearsBySection?.[getIdOrNull(section)]).map(sty => ({
    label: sty.schoolYearName,
    value: sty.id,
    tooltip: `${sty.sectionName} ${sty.schoolYearName ? '(' + sty.schoolYearName + ')' : ''}`,
    entity: sty
  })), [section, sectionToYearsBySection])

  return (
    <EntitiesSelector
      disabled={disabled || !getIdOrNull(section)}
      entityOptions={sectionToYearOptions}
      multiple={multiple}
      showSearch
      style={style}
      values={values}
      placeholderOption={placeholderOption}
      onChange={onChange}
    />
  )
}

export default SectionToYearSelector
