import React, { useContext } from 'react'

import { connect } from 'react-redux'
import { getTranslate } from 'react-localize-redux'
import TitledSelector from '../../../../Components/institution/Quotas/allocation/TitledSelector'
import InstitutionSchoolsList from './InstitutionSchoolsList'
import { InstitutionContext } from '../../../../Providers/InstitutionProvider'

const DEFAULT_STYLE = {
  width: '100%',
  maxWidth: '400px',
  minWidth: '125px',
  height: '100%',
  padding: '10px',
  paddingRight: '10px',
  border: '1px solid #e8e8e8',
  borderRadius: '10px',
  overflowY: 'auto'
}
const TITLED_SELECTOR_STYLE = {
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  justifyContent: 'center',
  paddingLeft: '5px',
  paddingRight: '5px',
  marginBottom: '10px'
}

const mapStateToProps = state => ({ t: getTranslate(state.locale) })

const Settings = ({ onSelectSchool, onSelectSector, t }) => {
  const { sectors } = useContext(InstitutionContext)

  return (
    <div style={DEFAULT_STYLE}>
      <TitledSelector
        style={TITLED_SELECTOR_STYLE}
        data={sectors}
        dataKey='name'
        title={t('Care unit')}
        placeholder={t('Choose a care unit')}
        onDataSelected={onSelectSector}
      />
      <div style={{ height: 'calc(100% - 145px)' }}>
        <InstitutionSchoolsList onSelect={onSelectSchool} />
      </div>
    </div>
  )
}

export default connect(mapStateToProps)(Settings)
