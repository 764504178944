import React, { useMemo } from 'react'

import Cookies from 'js-cookie'

const DefaultContainer = ({ children }) => {
  const height = useMemo(() => `calc(100vh - ${Cookies.get('switch_user_id') ? '180px' : '139px'})`, [Cookies.get('switch_user_id')])

  return (
    <div className='flex-fill d-flex flex-column' style={{ height, width: 'calc(100vw - 80px)' }}>
      {children}
    </div>
  )
}

export default DefaultContainer
