import React, { useContext, useState, useMemo } from 'react'

import { connect } from 'react-redux'
import { getTranslate } from 'react-localize-redux'
import { ArrayColorsPalette } from '../../../../config/colors'
import { InstitutionContext } from '../../../../Providers/InstitutionProvider'
import { applyOpacityToHex, getLuminanceFromColorCode } from '../../../../utils/colors'
import { Input, Tooltip } from 'antd'
import { isString } from 'lodash'

const { Search } = Input

const mapStateToProps = state => ({ t: getTranslate(state.locale) })

const InstitutionSchoolsList = ({ onSelect, t }) => {
  const { schools } = useContext(InstitutionContext)

  const [search, setSearch] = useState('')
  const [selectedSchoolId, setSelectedSchoolId] = useState(null)

  const coloredSchools = useMemo(() => schools.map((school, index) => {
    const color = ArrayColorsPalette[index % ArrayColorsPalette.length].value

    school.color = {
      border: color,
      background: applyOpacityToHex(color, 0.5),
      text: getLuminanceFromColorCode(color)
    }

    return school
  }), [schools])

  const filteredSchools = useMemo(() => {
    const lowerCaseSearch = search.toLowerCase()

    return coloredSchools.filter(school =>
      school.name.toLowerCase().includes(lowerCaseSearch) || (isString(school.abbreviation) && school.abbreviation.toLowerCase().includes(lowerCaseSearch))
    )
  }, [coloredSchools, search])

  const handleClick = (id) => {
    const schoolId = id === selectedSchoolId ? null : id

    setSelectedSchoolId(schoolId)
    onSelect(schoolId)
  }

  return (
    <div style={{ height: '100%' }}>
      <h3>{t('data_table.column_title.school')}</h3>
      <div style={{ height: '100%' }}>
        <Search
          placeholder={t('filter.search')}
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          style={{ maxWidth: '400px' }}
        />
        <div style={{ overflowY: 'auto', height: '100%' }}>
          {filteredSchools.map((school) => (
            <div
              key={school.id}
              className='border rounded-lg p-3 shadow-sm cursor-pointer'
              style={{
                backgroundColor: school.color.background,
                color: school.color.border,
                height: '40px',
                maxWidth: '400px',
                border: school.id === selectedSchoolId
                  ? `3px solid ${school.color.border}`
                  : 'none',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                textAlign: 'center',
                padding: '10px',
                overflow: 'hidden'
              }}
              onClick={() => handleClick(school.id)}
            >
              <Tooltip title={school.name}>
                <span
                  style={{
                    color: school.color.text,
                    width: '100%',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    textAlign: 'center',
                    fontSize: '14px',
                    display: '-webkit-box',
                    WebkitLineClamp: 2,
                    WebkitBoxOrient: 'vertical'
                  }}
                >
                  {school.abbreviation ?? school.name}
                </span>
              </Tooltip>
            </div>
          ))}
        </div>

      </div>
    </div>
  )
}

export default connect(mapStateToProps)(InstitutionSchoolsList)
