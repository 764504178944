import React, { createContext, useCallback, useState } from 'react'

import { Modal } from 'antd'
import { connect } from 'react-redux'
import { getTranslate } from 'react-localize-redux'
import { isFunction } from 'lodash'

import { getUser } from '../../../reducers/UserReducer'
import { onError, onSuccess } from '../../../utils/apiHelper'
import { createQuota, updateQuota } from '../../../utils/api/quota'
import { Actions } from '../../../Components/antd/Tables/utils'
import { isNotNullObject, isOptimistic } from '../../../utils'
import QuotaForm from './QuotaForm'
import { quotaFromResponse, Quota } from '../../../utils/entities/quota'

export const QuotaFormModalContext = createContext()

const DEFAULT_FORM_HANDLER = { data: null, callback: null }

const mapStateToProps = state => ({
  t: getTranslate(state.locale),
  user: getUser(state.getUser)
})

const QuotaFormModalProvider = ({ children, user, t }) => {
  const [formHandler, setFormHandler] = useState(DEFAULT_FORM_HANDLER)

  /** @type {(quota: Quota) => any} */
  const handleSubmit = useCallback(quota => {
    const isNewQuota = isOptimistic(quota)

    const request = isNewQuota ? createQuota : updateQuota
    const action = isNewQuota ? Actions.CREATE : Actions.UPDATE

    formHandler.callback(quota, action)

    request(user, quota)
      .then(json => {
        if (isFunction(formHandler.callback)) {
          const responseData = quotaFromResponse(quota, json)
          responseData.isNewQuota = isNewQuota

          formHandler.callback(responseData, Actions.UPDATE)
        }

        onSuccess(t(`quota_form_modal.${action.toLowerCase()}.success`))
      })
      .catch(reason => {
        console.error(reason)

        formHandler.callback(formHandler.data, isNewQuota ? Actions.DELETE : Actions.UPDATE)

        onError(t(`quota_form_modal.${action.toLowerCase()}.error`))
      })
      .finally(handleCloseModal)
  }, [t, user, formHandler.callback, createQuota, updateQuota, onSuccess, onError])

  const handleCloseModal = useCallback(() => setFormHandler(DEFAULT_FORM_HANDLER), [setFormHandler])

  return (
    <>
      <QuotaFormModalContext.Provider value={{ setFormHandler }}>
        {children}
      </QuotaFormModalContext.Provider>
      <Modal
        className='form-modal'
        title={t('quota_form_modal.title') + `${formHandler.data?.sector?.name ? ' (' + formHandler.data?.sector?.name + ')' : ''}`}
        visible={isNotNullObject(formHandler.data)}
        onCancel={handleCloseModal}
        destroyOnClose
        footer={null}
      >
        {isNotNullObject(formHandler.data) && (
          <QuotaForm
            data={formHandler.data}
            onSubmit={handleSubmit}
            onCancel={handleCloseModal}
          />
        )}
      </Modal>
    </>
  )
}

export default connect(mapStateToProps)(QuotaFormModalProvider)
