import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { isFunction } from 'lodash'
import { getLuminanceFromColorCode } from '../../../../utils/colors'
import { KeyboardKeys } from '../../../../utils/constants'
import { ColorsPalette } from '../../../../config/colors'
import { getDisplacement } from '../utils'

const MAT = 1
const TRANSPARENT = 0.7
const MOVE_LEFT = 'move_left'
const MOVE_RIGHT = 'move_right'
const MOVE_TOP = 'move_top'
const MOVE_BOTTOM = 'move_bottom'
const MOVE_DIAGONAL_TOP_LEFT = 'move_diagonal_top_left'
const MOVE_DIAGONAL_TOP_RIGHT = 'move_diagonal_top_right'
const MOVE_DIAGONAL_BOTTOM_LEFT = 'move_diagonal_bottom_left'
const MOVE_DIAGONAL_BOTTOM_RIGHT = 'move_diagonal_bottom_right'

const CONFIG = {
  style: { border: '1px solid darkgrey', borderRadius: '12px' },
  boundaries: { x: [0, 1], y: [0, 1] },
  displacement: { x: 0, y: 0 },
  scrollOffset: { left: 0, top: 0 }
}

const draggableSpace = '10px'

const ResizingDiv = ({
  config = CONFIG, content, data = { left: [1, 1], top: [0, 0], id: -1 }, selected = false,
  onClick, onDragStop, onMaxIndexOverflow, onRightDisplacement, onDoubleClick, onDeselect
}) => {
  const [initialLocation, setInitialLocation] = useState({ x: 0, y: 0 })
  const [initialIndexes, setInitalIndexes] = useState({ left: 0, top: 0 })
  const [indexes, setIndexes] = useState({ left: data.x, top: data.y })
  const [opacity, setOpacity] = useState(MAT)

  const boundaries = useMemo(() => config.boundaries ?? CONFIG.boundaries, [config])
  const configStyle = useMemo(() => ({ ...CONFIG, ...(config?.style ?? {}) }), [config])
  const displacement = useMemo(() => ({ ...CONFIG.displacement, ...(config?.displacement ?? {}) }), [config])
  const offsetX = boundaries.x[0]
  const offsetY = boundaries.y[0]
  const scrollOffset = useMemo(() => ({ ...CONFIG.scrollOffset, ...(config?.scrollOffset ?? {}) }), [config])

  const moveRef = useRef(null)
  const containerRef = useRef(null)

  useEffect(() => {
    if (selected && containerRef.current) {
      containerRef.current.focus()
    }
  }, [selected, containerRef.current])

  useEffect(() => {
    let left = [0, 0]
    let top = [0, 0]

    if (data.left) {
      left = [data.left[0], data.left[1]]
    } else if (data.x) {
      left = [data.x[0], data.x[1]]
    }

    if (data.top) {
      top = [data.top[0] - 1, data.top[1] - 1]
    } else if (data.y) {
      top = [data.y[0] - 1, data.y[1] - 1]
    }

    setIndexes({ left, top })
  }, [data])

  const style = useMemo(() => {
    const { left, top } = indexes
    const currentLeft = (displacement.x * (left[0] + offsetX))
    const currentTop = (displacement.y * (top[0] + offsetY))
    const height = `${displacement.y * (top[1] + 1 - top[0]) + 1}px`
    const width = `${displacement.x * (left[1] + 1 - left[0]) + 1}px`
    const { background, border, borderRadius } = configStyle

    const style = {
      left: currentLeft,
      top: currentTop,
      height,
      width,
      border,
      borderRadius,
      opacity,
      background,
      backgroundColor: data.color ?? 'white'
    }

    if (style.backgroundColor) {
      style.color = getLuminanceFromColorCode(style.backgroundColor)
    }

    if (selected) {
      style.border = '3px solid ' + ColorsPalette.GREY
      style.zIndex = '10'
    }

    return style
  }, [configStyle, indexes, opacity, selected])
  const space = useMemo(() => 2 * draggableSpace.slice(0, draggableSpace.length - 2), [])

  const updateData = useCallback((data, newIndexes) => {
    const newData = { ...data }

    if (data.left) {
      newData.left = newIndexes.left
    } else if (data.x) {
      newData.x = newIndexes.left
    }

    if (data.top) {
      newData.top = newIndexes.top
    } else if (data.y) {
      newData.y = newIndexes.top
    }

    return newData
  }, [])

  const getXDisplacement = useCallback(location => {
    return getDisplacement(initialLocation.x, location, displacement.x)
  }, [initialLocation.x, displacement.x])

  const getYDisplacement = useCallback(location => {
    return getDisplacement(initialLocation.y, location, displacement.y)
  }, [initialLocation.y, displacement.y])

  const triggerOverflow = useCallback(index => {
    if (index >= boundaries.y[1] && isFunction(onMaxIndexOverflow)) {
      onMaxIndexOverflow(index - boundaries.y[1] + 1)
    }
  }, [onMaxIndexOverflow, boundaries.y[1]])

  const moveLeft = useCallback((e, indexes) => {
    if (indexes === null) return null

    const index = indexes.left[0] + getXDisplacement(e.clientX) + scrollOffset.left

    return index >= 0 && index <= indexes.left[1] ? { ...indexes, left: [index, indexes.left[1]] } : null
  }, [getXDisplacement])

  const moveRight = useCallback((e, indexes) => {
    if (indexes === null) return null

    const index = indexes.left[1] + getXDisplacement(e.clientX)

    return index < boundaries.x[1] && index >= indexes.left[0] ? { ...indexes, left: [indexes.left[0], index] } : null
  }, [getXDisplacement])

  const moveTop = useCallback((e, indexes) => {
    if (indexes === null) return null

    const index = indexes.top[0] + getYDisplacement(e.clientY)

    return index >= 0 && index <= indexes.top[1] ? { ...indexes, top: [index, indexes.top[1]] } : null
  }, [getYDisplacement])

  const moveBottom = useCallback((e, indexes) => {
    if (indexes === null) return null

    const index = indexes.top[1] + getYDisplacement(e.clientY)

    if (index > boundaries.y[1]) {
      triggerOverflow(index)
    }

    return index >= indexes.top[0] ? { ...indexes, top: [indexes.top[0], index] } : null
  }, [triggerOverflow, getYDisplacement, boundaries.y[1]])

  const handleDrag = useCallback((e, type) => {
    e.stopPropagation()
    e.preventDefault()

    let indexes = null

    if (type === MOVE_LEFT) {
      indexes = moveLeft(e, initialIndexes)
    } else if (type === MOVE_RIGHT) {
      indexes = moveRight(e, initialIndexes)
    } else if (type === MOVE_BOTTOM) {
      indexes = moveBottom(e, initialIndexes)
    } else if (type === MOVE_TOP) {
      indexes = moveTop(e, initialIndexes)
    } else if (type === MOVE_DIAGONAL_TOP_LEFT) {
      indexes = moveTop(e, moveLeft(e, initialIndexes))
    } else if (type === MOVE_DIAGONAL_TOP_RIGHT) {
      indexes = moveTop(e, moveRight(e, initialIndexes))
    } else if (type === MOVE_DIAGONAL_BOTTOM_LEFT) {
      indexes = moveBottom(e, moveLeft(e, initialIndexes))
    } else if (type === MOVE_DIAGONAL_BOTTOM_RIGHT) {
      indexes = moveBottom(e, moveRight(e, initialIndexes))
    }

    if (indexes !== null) {
      setIndexes(indexes)
    }
  }, [moveLeft, moveRight, moveBottom, moveTop, initialIndexes])

  const handleDragStart = useCallback(e => {
    setInitialLocation({ x: e.clientX, y: e.clientY })
    setInitalIndexes(indexes)
    setOpacity(TRANSPARENT)

    if (isFunction(onClick)) {
      onClick(data)
    }
  }, [setInitialLocation, setOpacity, onClick, data.id, indexes])

  const handleDragEnd = useCallback(e => {
    setInitialLocation({ x: 0, y: 0 })
    setInitalIndexes({ left: 0, top: 0 })
    setOpacity(MAT)

    if (isFunction(onDragStop)) {
      onDragStop(updateData(data, indexes))
    }
  }, [setOpacity, updateData, indexes, data.id, onDragStop])

  const draggableProps = useMemo(() => {
    return {
      onDragEnd: handleDragEnd,
      onDragStart: handleDragStart,
      draggable: true
    }
  }, [handleDragEnd, handleDragStart])

  const handleMove = useCallback(e => {
    e.preventDefault()
    e.stopPropagation()

    if (e.clientX && e.clientY && initialLocation.x && initialLocation.y) {
      const newIndexes = { ...initialIndexes }
      const displacementX = getXDisplacement(e.clientX)
      const displacementY = getYDisplacement(e.clientY)

      const startLeft = newIndexes.left[0] + displacementX
      newIndexes.left = [startLeft, startLeft <= 0 ? initialIndexes.left[1] - initialIndexes.left[0] : newIndexes.left[1] + displacementX]

      const startTop = newIndexes.top[0] + displacementY
      newIndexes.top = [startTop, startTop <= 0 ? initialIndexes.top[1] - initialIndexes.top[0] : newIndexes.top[1] + displacementY]

      if (newIndexes.left[0] < 0) {
        newIndexes.left[0] = 0
      }

      if (newIndexes.top[0] < 0) {
        newIndexes.top[0] = 0
      }

      if (newIndexes.left[1] > boundaries.x[1]) {
        newIndexes.left[1] = boundaries.x[1]
        newIndexes.left[0] = boundaries.x[1] - (initialIndexes.left[1] - initialIndexes.left[0])
      }

      if (newIndexes.left[0] !== indexes.left[0] || newIndexes.top[0] !== indexes.top[0] || newIndexes.left[1] !== indexes.left[1] || newIndexes.top[1] !== indexes.top[1]) {
        setIndexes(newIndexes)
      }

      if (newIndexes.top[1] > boundaries.y[1]) {
        triggerOverflow(newIndexes.top[1])
      }
    }
  }, [boundaries.x, indexes, initialIndexes, setIndexes, triggerOverflow, getXDisplacement, getYDisplacement])

  const saveMove = useCallback(indexes => {
    setIndexes(indexes)

    if (isFunction(onDragStop)) {
      if (moveRef.current) {
        clearTimeout(moveRef.current)
      }

      moveRef.current = setTimeout(() => {
        onDragStop(updateData(data, indexes))
      }, 1000)
    }
  }, [onDragStop, updateData, data, moveRef.current])

  const handleKeyDown = useCallback(e => {
    const newIndexes = { ...indexes }

    if (e.key === KeyboardKeys.ARROW_DOWN) {
      if (e.ctrlKey) {
        newIndexes.top = [newIndexes.top[0], newIndexes.top[1] + 1]
        triggerOverflow(newIndexes.top[1])
      } else if (e.shiftKey) {
        if (newIndexes.top[0] < newIndexes.top[1]) {
          const newIndex = newIndexes.top[1] - 1

          newIndexes.top = [newIndexes.top[0], newIndex >= 0 ? newIndex : 0]
        }
      } else {
        newIndexes.top = [newIndexes.top[0] + 1, newIndexes.top[1] + 1]
        triggerOverflow(newIndexes.top[1])
      }

      saveMove(newIndexes)
    } else if (e.key === KeyboardKeys.ARROW_UP) {
      if (e.ctrlKey) {
        const newIndex = newIndexes.top[0] - 1

        newIndexes.top = [newIndex >= 0 ? newIndex : 0, newIndexes.top[1]]
      } else if (e.shiftKey) {
        if (newIndexes.top[0] < newIndexes.top[1]) {
          newIndexes.top = [newIndexes.top[0] + 1, newIndexes.top[1]]
        }
      } else {
        const newIndex0 = newIndexes.top[0] - 1
        const newIndewx1 = newIndexes.top[1] - 1

        if (newIndex0 >= 0) {
          newIndexes.top = [newIndex0, newIndewx1]
        }
      }

      saveMove(newIndexes)
    } else if (e.key === KeyboardKeys.ARROW_LEFT) {
      if (e.ctrlKey) {
        const newIndex = newIndexes.left[0] - 1

        newIndexes.left = [newIndex >= 0 ? newIndex : 0, newIndexes.left[1]]
      } else if (e.shiftKey) {
        if (newIndexes.left[0] < newIndexes.left[1]) {
          newIndexes.left = [newIndexes.left[0] + 1, newIndexes.left[1]]
        }
      } else {
        const newIndex0 = newIndexes.left[0] - 1
        const newIndewx1 = newIndexes.left[1] - 1

        if (newIndex0 >= 0) {
          newIndexes.left = [newIndex0, newIndewx1]
        }
      }

      saveMove(newIndexes)
    } else if (e.key === KeyboardKeys.ARROW_RIGHT) {
      if (e.ctrlKey) {
        if (newIndexes.left[1] < boundaries.x[1]) {
          newIndexes.left = [newIndexes.left[0], newIndexes.left[1] + 1]
        }
      } else if (e.shiftKey) {
        if (newIndexes.left[0] < newIndexes.left[1]) {
          const newIndex = newIndexes.left[1] - 1

          newIndexes.left = [newIndexes.left[0], newIndex >= 0 ? newIndex : 0]
        }
      } else {
        if (newIndexes.left[1] < boundaries.x[1]) {
          newIndexes.left = [newIndexes.left[0] + 1, newIndexes.left[1] + 1]
        }
      }

      saveMove(newIndexes)
    }
  }, [boundaries.x, indexes, triggerOverflow, saveMove])

  const handleDoubleClick = useCallback(() => {
    if (onDoubleClick) {
      onDoubleClick(data)
    }
  }, [data, onDoubleClick])

  const handleDeselect = useCallback(e => {
    if (isFunction(onDeselect)) {
      onDeselect()
    }
  }, [onDeselect])

  return (
    <div
      id='resizable-div'
      ref={containerRef}
      style={style}
      tabIndex='0'
      onClick={() => onClick(data)}
      onDoubleClick={handleDoubleClick}
      onKeyDown={handleKeyDown}
      onBlur={handleDeselect}
    >
      <div className='side-container w-sm' style={{ height: style.height }}>
        <div
          className='corner top-left-corner'
          onDrag={e => handleDrag(e, MOVE_DIAGONAL_TOP_LEFT)}
          {...draggableProps}
        />
        <div
          id='left-side'
          style={{ height: `calc(${style.height} - ${space}px)` }}
          onDrag={e => handleDrag(e, MOVE_LEFT)}
          {...draggableProps}
        />
        <div
          className='corner bottom-left-corner'
          onDrag={e => handleDrag(e, MOVE_DIAGONAL_BOTTOM_LEFT)}
          {...draggableProps}
        />
      </div>
      <div className='side-container'>
        <div
          id='top-side'
          style={{ width: `calc(${style.width} - ${space}px)` }}
          onDrag={e => handleDrag(e, MOVE_TOP)}
          {...draggableProps}
        />
        <div
          id='content-container'
          style={{ width: `calc(${style.width} - ${space}px)`, height: `calc(${style.height} - ${space}px)` }}
          {...draggableProps}
          onDrag={handleMove}
        >
          {content} {`(${indexes.top[1] - indexes.top[0] + 1})`}
        </div>
        <div
          id='bottom-side'
          style={{ width: `calc(${style.width} - ${space}px)` }}
          onDrag={e => handleDrag(e, MOVE_BOTTOM)}
          {...draggableProps}
        />
      </div>
      <div className='side-container w-sm' style={{ height: style.height }}>
        <div className='corner top-right-corner' onDrag={e => handleDrag(e, MOVE_DIAGONAL_TOP_RIGHT)} {...draggableProps} />
        <div
          id='right-side'
          style={{ height: `calc(${style.height} - ${space}px)` }}
          onDrag={e => handleDrag(e, MOVE_RIGHT)}
          {...draggableProps}
        />
        <div className='corner bottom-right-corner' onDrag={e => handleDrag(e, MOVE_DIAGONAL_BOTTOM_RIGHT)} {...draggableProps} />
      </div>
    </div>
  )
}

export default ResizingDiv
