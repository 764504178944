import React, { useCallback, useEffect, useMemo, useState } from 'react'

import useRequest from '../../../../hooks/useRequest'
import { Button, Checkbox, Drawer } from 'antd'
import { getTranslate } from 'react-localize-redux'
import { connect } from 'react-redux'
import { getUser } from '../../../../reducers/UserReducer'
import { fetchActTypeSections, linkSectionsToActType, unlinkSectionsToActType } from '../../../../utils/api/actType'
import Loading from '../../../../HOC/Loading'
import Search from 'antd/lib/input/Search'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSave } from '@fortawesome/pro-solid-svg-icons'
import { onSuccess, resolvePromises } from '../../../../utils/apiHelper'
import { TableActions } from '../../../../Components/antd/Tables/DataTable/utils/actions'

import '../../../../assets/act-types-view.scss'

const mapStateToProps = state => ({ t: getTranslate(state.locale), user: getUser(state.getUser) })

const ActTypeSectionsDrawer = ({ children, sections = [], user, onTriggerAction, t }) => {
  const [selectedActType, setSelectedActType] = useState(null)
  const [selectedSections, setSelectedSections] = useState([])
  const [visible, setVisible] = useState(false)
  const [search, setSearch] = useState('')

  const actTypeSections = useRequest({ request: fetchActTypeSections, parameters: useMemo(() => [user, selectedActType], [user, selectedActType]) })

  const sectionOptions = useMemo(() => {
    return sections.map(s => {
      const { sectionAbbreviation, schoolYearName } = s.sectionToYear

      return { label: `${sectionAbbreviation} (${schoolYearName})`, value: s.id }
    }).filter(s => search === '' || s.label.toLowerCase().includes(search.toLowerCase()))
  }, [sections, search])
  const allChecked = useMemo(() => selectedSections.length >= sectionOptions.length, [selectedSections.length, sectionOptions.length])

  const handleActionTrigger = useCallback((entity, action) => {
    if (action === TableActions.DISPLAY_ACT_TYPE_SECTIONS) {
      setSelectedActType(entity)
      setVisible(true)
    } else if (typeof onTriggerAction === 'function') {
      onTriggerAction(entity, action)
    }
  }, [setSelectedActType, setVisible, onTriggerAction])

  const handleSelectAll = useCallback(() => {
    if (allChecked) {
      setSelectedSections(selectedSections.filter(id => !sectionOptions.find(so => so.value === id)))
    } else {
      const newOptions = actTypeSections.data.map(s => s.id)

      sectionOptions.forEach(so => {
        if (!actTypeSections.data.find(s => s.id === so.value)) {
          newOptions.push(so.value)
        }
      })

      selectedSections.forEach(id => {
        if (!newOptions.includes(id)) {
          newOptions.push(id)
        }
      })

      setSelectedSections(newOptions)
    }
  }, [actTypeSections.data, allChecked, setSelectedSections, sectionOptions, selectedSections])

  const handleSave = useCallback(() => {
    const sectionsToBeAdded = selectedSections.filter(id => !actTypeSections.data.find(s => s.id === id))
    const sectionsToBeRemoved = actTypeSections.data.filter(s => !selectedSections.find(id => id === s.id)).map(s => s.id)
    const promiseStack = []

    if (sectionsToBeAdded.length > 0) {
      promiseStack.push(linkSectionsToActType(user, selectedActType, sectionsToBeAdded))
    }

    if (sectionsToBeRemoved.length > 0) {
      promiseStack.push(unlinkSectionsToActType(user, selectedActType, sectionsToBeRemoved))
    }

    resolvePromises(promiseStack).then(() => {
      onSuccess(t('act_type_sections_drawer.actions.link_unlink'))
    })
  }, [actTypeSections.data, selectedActType, selectedSections, user])

  useEffect(() => {
    setSelectedSections(actTypeSections.data.map(s => s.id))
  }, [actTypeSections.data])

  return (
    <>
      {React.cloneElement(children, { onTriggerAction: handleActionTrigger })}
      <Drawer
        title={t('act_type_sections_drawer.title')}
        visible={visible}
        width={400}
        onClose={() => setVisible(false)}
        destroyOnClose
      >
        {selectedActType && (
          <Loading loading={actTypeSections.loading}>
            <div className='act-type-school-sections-list-container'>
              <Search value={search} onChange={e => setSearch(e.target.value)} />
              <div className='v-space' />
              <Checkbox onChange={handleSelectAll} checked={selectedSections.length >= sectionOptions.length}>
                <b> {t('Select all')} </b>
              </Checkbox>
              <Checkbox.Group options={sectionOptions} value={selectedSections} onChange={setSelectedSections} />
              <Button type='primary' onClick={handleSave}> <FontAwesomeIcon icon={faSave} /> {t('Save')} </Button>
            </div>
          </Loading>
        )}
      </Drawer>
    </>
  )
}

export default connect(mapStateToProps)(ActTypeSectionsDrawer)
