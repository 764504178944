import { FULL_DATE_WITHOUT_TIMEZONE } from '../constants'
import { ValidationFields } from '../validators'
import { getIdOrNull, isPositive, matchWithId } from '..'
import { isMoment } from 'moment'
import { isFinite } from 'lodash'
import { getTimezoneLessMoment } from '../momentjs'
import { API_DATE_FORMAT } from '../apiHelper'

/** @enum {string} */
export const QuotaProperties = Object.freeze({
  SECTOR: ValidationFields.SECTOR,
  SECTION: ValidationFields.SECTION,
  SCHOOL: ValidationFields.SCHOOL,
  SECTION_TO_YEAR: ValidationFields.SECTION_TO_YEAR,
  START_DATE: ValidationFields.START_DATE,
  END_DATE: ValidationFields.END_DATE,
  TOTAL: ValidationFields.TOTAL
})

export const QUOTA_VALIDATORS = [
  { key: QuotaProperties.SECTOR, validator: quota => isFinite(getIdOrNull(quota.sector)) },
  { key: QuotaProperties.SCHOOL, validator: quota => !!getIdOrNull(quota.school) },
  { key: QuotaProperties.START_DATE, validator: quota => isMoment(quota.startDate) },
  { key: QuotaProperties.END_DATE, validator: quota => isMoment(quota.endDate) },
  { key: QuotaProperties.TOTAL, validator: quota => isPositive(quota.total) }
]

export const areQuotaEquivalent = (first, second) => {
  if (first == null || second == null) {
    return false
  }

  const withIdKeys = [QuotaProperties.SECTOR, QuotaProperties.SCHOOL, QuotaProperties.SECTION, QuotaProperties.SECTION_TO_YEAR]
  const datesKeys = [QuotaProperties.START_DATE, QuotaProperties.END_DATE]

  const unequivalentKeys = Object.keys(first).filter(key => {
    if (datesKeys.includes(key)) {
      return getTimezoneLessMoment(first[key]).format(API_DATE_FORMAT) !== getTimezoneLessMoment(second[key]).format(API_DATE_FORMAT)
    } else if (withIdKeys.includes(key)) {
      return getIdOrNull(first?.[key]) !== getIdOrNull(second?.[key])
    } else {
      return first[key] !== second[key]
    }
  })

  return unequivalentKeys.length === 0
}

/**
 * @param {Quota} quota 
 * @param {{data: object}} response 
 * @returns {Quota}
 */
export const quotaFromResponse = (quota, response) => {
  const responseData = new Quota(response.data)
  responseData.school = matchWithId(quota.school, responseData.school)
  responseData.section = matchWithId(quota.section, responseData.section)
  responseData.sectionToYear = matchWithId(quota.sectionToYear, responseData.sectionToYear)

  return responseData
}

export class Quota {
  constructor({ school, sector, section, sectionToYear, total = 1, startDate, endDate, id = -1 } = {}) {
    this.id = id
    this.startDate = getTimezoneLessMoment(startDate)
    this.endDate = getTimezoneLessMoment(endDate)
    this.school = school
    this.sector = sector
    this.section = section
    this.sectionToYear = sectionToYear
    this.total = total
  }

  get toBody () {
    return {
      sector: getIdOrNull(this.sector.id),
      section: getIdOrNull(this.section),
      school: getIdOrNull(this.school),
      sectionToYear: getIdOrNull(this.sectionToYear),
      startDate: getTimezoneLessMoment(this.startDate).format(FULL_DATE_WITHOUT_TIMEZONE),
      endDate: getTimezoneLessMoment(this.endDate).format(FULL_DATE_WITHOUT_TIMEZONE),
      total: getIdOrNull(this.total)
    }
  }
}
