import React, { useCallback, useContext, useMemo, useState } from 'react'

import { getTranslate } from 'react-localize-redux'
import { getUser } from '../reducers/UserReducer'
import { Modal, Select } from 'antd'
import { connect } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationTriangle } from '@fortawesome/pro-solid-svg-icons'
import { InstitutionGroupContext } from '../Providers/InstitutionGroupProvider'
import { onSuccess } from '../utils/apiHelper'
import { switchSectorInstitution } from '../utils/api/sector'
import { TableActions } from '../Components/antd/Tables/DataTable/utils/actions'

const mapStateToProps = state => ({ t: getTranslate(state.locale), user: getUser(state.getUser) })

const SwitchSectorInstitutionModal = ({ children, user, onTriggerAction, onSave, t }) => {
  const igContext = useContext(InstitutionGroupContext)

  const [selectedSector, setSelectedSector] = useState(null)
  const [selectedInstitution, setSelectedInstitution] = useState(null)
  const [visible, setVisible] = useState(false)

  const handleActionTrigger = useCallback((entity, action) => {
    if (action === TableActions.SWITCH_SECTOR) {
      setSelectedSector(entity)
      setVisible(true)
    } else if (typeof onTriggerAction === 'function') {
      onTriggerAction(entity, action)
    }
  }, [setSelectedSector, setSelectedSector, onTriggerAction])

  const switchInstitution = useCallback(() => {
    switchSectorInstitution(user, selectedSector, selectedInstitution).then(json => {
      if (json?.data) {
        onSuccess(t('switch_sector_institution_modal.switch.success'))
        setVisible(false)

        if (typeof onSave === 'function') {
          onSave(json.data)
        }
      }
    })
  }, [selectedInstitution, selectedSector, user, t])

  const institutions = useMemo(() => igContext?.institutions ?? [], [igContext])

  return (
    <>
      {React.cloneElement(children, { onTriggerAction: handleActionTrigger })}
      <Modal
        visible={visible}
        onCancel={() => setVisible(false)}
        onOk={switchInstitution}
        title={t('switch_sector_institution_modal.title')}
        cancelText={t('modal.cancel_button')}
        okText={t('switch_sector_institution_modal.buttons.ok')}
        okButtonProps={{ disabled: selectedInstitution === null || selectedInstitution.id === selectedSector.institution.id }}
        afterClose={() => {
          setSelectedSector(null)
          setSelectedInstitution(null)
        }}
      >
        {selectedSector && (
          <div className='flex-column'>
            <div>
              <FontAwesomeIcon icon={faExclamationTriangle} color='orange' />
              <b> {t('Warning:')} </b>
              {t('switch_sector_institution_modal.body.sector_presentation')} <b> {selectedSector?.name} </b>
            </div>
            <div className='v-spacing' />
            <div>
              {t('switch_sector_institution_modal.body.side_effect')}
              <ul>
                <li> {t('switch_sector_institution_modal.body.nurses')} </li>
                <li> {t('switch_sector_institution_modal.body.admins')} </li>
                <li> {t('switch_sector_institution_modal.body.internships')} </li>
                <li> {t('switch_sector_institution_modal.body.schools')} </li>
                <li> {t('switch_sector_institution_modal.body.school_permissions')} </li>
              </ul>
            </div>
            <span> {t('switch_sector_institution_modal.body.conclusion')} </span>
            <div className='v-spacing' />
            <Select
              value={selectedInstitution?.id ?? null}
              onChange={value => setSelectedInstitution({ id: value })}
            >
              {institutions.map(i => {
                return (
                  <Select.Option key={i.id} value={i.id}>
                    {i.name}
                  </Select.Option>
                )
              })}
            </Select>
          </div>
        )}
      </Modal>
    </>
  )
}

export default connect(mapStateToProps)(SwitchSectorInstitutionModal)
