const INTERNEO_FAQ_URL_PART_1 = 'https://support.opalsolutions.be/hc/'
const INTERNEO_FAQ_URL_PART_2 = '/categories/360003066859-Comment-utiliser-Interneo'

// #region Languages

/** @enum {string} */
export const LanguageTags = Object.freeze({
  EN: 'en',
  FR: 'fr',
  NL: 'nl'
})

/** @enum {string} */
export const LanguageLocales = Object.freeze({
  EN: 'en',
  FR_BE: 'fr_be',
  FR_FR: 'fr_fr',
  NL: 'nl'
})

export const SUPPORTED_LANGUAGES = [LanguageTags.EN, LanguageTags.FR, LanguageTags.NL]
export const SUPPORTED_LANGUAGE_LOCALES = [LanguageLocales.EN, LanguageLocales.FR_BE, LanguageLocales.FR_FR, LanguageLocales.NL]

// #endregion

/** @enum {string} */
export const ExportTypes = Object.freeze({
  CSV: 'csv',
  XLSX: 'xlsx'
})

/** Part of the constant related to user roles */
export const STANDARD_DRAWER_WIDTH = '550px'

/** @enum {string} */
export const Dimensions = Object.freeze({
  XS: 768,
  SM: 992,
  MD: 1200
})

// #region User Roles

/** @enum {string} */
export const InstitutionRoles = Object.freeze({
  INSTITUTION_GROUP_ADMIN: 'ROLE_INSTITUTION_GROUP_ADMIN',
  HOSPITAL_ADMIN: 'ROLE_HOSPITAL_ADMIN',
  NURSE: 'ROLE_NURSE',
  OBSERVER: 'ROLE_OBSERVER'
})

/** @enum {string} */
export const SchoolRoles = Object.freeze({
  ENCODER: 'ROLE_ENCODER',
  COORDINATOR: 'ROLE_COORDINATOR',
  SUPERVISOR: 'ROLE_SUPERVISOR',
  VALIDATOR: 'ROLE_VALIDATOR',
  DOMAIN_EVALUATOR: 'ROLE_DOMAIN_EVALUATOR'
})

/** @enum {string} */
export const OtherRoles = Object.freeze({
  ADMIN: 'ROLE_ADMIN',
  STUDENT: 'ROLE_STUDENT',
  UNKNOWN: 'ROLE_UNKNOWN'
})

/** @enum {string} */
export const SupportedRoles = Object.freeze({
  ...OtherRoles,
  ...SchoolRoles,
  ...InstitutionRoles
})

// #endregion

// #region Contexts

/** @enum {string} */
export const Contexts = Object.freeze({
  ADMIN: 'ADMIN',
  SCHOOL: 'SCHOOL',
  INSTITUTION: 'HOSPITAL',
  STUDENT: 'STUDENT',
  NONE: 'NONE'
})

// #endregion

// #region Order by

/** @enum {string} */
export const OrderBy = Object.freeze({
  ACT_TYPE: 'act-type',
  ADDRESS: 'address',
  BIRTHDATE: 'birthdate',
  CATEGORY: 'category',
  CITY: 'city',
  COMMENT: 'comment',
  COUNTRY: 'country',
  CREATED_AT: 'created-at',
  DESCRIPTION: 'description',
  EMAIL: 'email',
  EMPLACEMENT: 'emplacement',
  END_DATE: 'end-date',
  FIRSTNAME: 'firstname',
  FIRST_NAME: 'first-name',
  HOSPITAL_DEPARTMENT: 'hospital-department',
  INSTITUTION: 'institution',
  LANGUAGE: 'language',
  LASTNAME: 'lastname',
  LAST_NAME: 'last-name',
  LAST_CONNECTION: 'last-login',
  MANAGERS: 'managers',
  NAME: 'name',
  ORIGINAL_NAME: 'original-name',
  PHONE_NUMBER: 'phone-number',
  ZIPCODE: 'zipcode',
  REGISTRATION: 'registration-number',
  REQUEST_ORIGIN: 'request-origin',
  ROLE: 'role',
  SCHOOL: 'school',
  SCHOOL_YEAR: 'school-year',
  SCHOOL_YEAR_NAME: 'school-year-name',
  SECTION: 'section',
  SECTOR: 'sector',
  STATE: 'state',
  START_DATE: 'start-date',
  TYPE: 'type',
  USERNAME: 'username',
  COORDINATOR: 'coordinator',
  STUDENT: 'student'
})

/** @enum {string} */
export const OrderDirections = Object.freeze({
  ASC: 'asc',
  DESC: 'desc'
})

// #endregion

export const DEFAULT_NUMBER_OF_WEEKS = 24
export const SEARCH_BADGE = 'search-badge'

/** @enum {string} */
export const Modes = Object.freeze({
  INTERNEO: 'INTERNEO',
  WIDGET: 'WIDGET'
})

/** @enum {string} */
export const TextColors = Object.freeze({
  LIGHT: 'white',
  DARK: 'black'
})

/** @enum {string} */
export const QuotaTypes = Object.freeze({
  FREE_STUDENT: 'FREE_STUDENT',
  OTHERS: 'OTHERS'
})

// #region Options

/** @enum {string} */
export const InstitutionOptions = Object.freeze({
  DISPLAY_QUOTA_PER_SECTOR: 'display_quotas_per_sector',
  MODULE_SETTINGS: 'module_settings',
  DISPLAY_INTERNSHIPS_WITHOUT_STUDENT: 'display_internships_without_student',
  PUBLIPOSTAGE: 'publipostage',
  RECRUITMENT_MODULE: 'recruitment_module',
  QUOTAS_PER_SECTOR: 'display_quotas_per_sector',
  RECRUITEMENT_GREEN_MATCH_EMAILING: 'recruitment_green_match_emailing',
  QUOTA_PER_PAUSE: 'quota_per_pause',
  AUTO_ACCEPTATION_DAYS: 'auto-acceptation-days',
  DRAFT_INTERNSHIPS: 'draft_internships',
  INTERNSHIPS_AUTO_ACCEPTATION: 'internships_auto_acceptation',
  SCHEDULE_EDITION_BY_SCHOOLS: 'schedule_edition_by_schools',
  STUDENT_DESIDERATA_LIMIT: 'student_desiderata_limit',
  NURSE_INTERNSHIP_ACCCEPTANCE: 'nurse_internship_acceptance',
  BLOCK_SCHEDULE_CODE_EDITION: 'block_schedule_code_edition',
  UPDATE_GLOBAL_QUOTA: 'update_global_quota',
  TIVOLI_CUSTOM_EXPORT: 'tivoli_custom_export',
  SSO_BY_DEFAULT: 'sso_by_default'
})

/** @enum {string} List of all schools options */
export const SchoolOptions = Object.freeze({
  SUPERVISORS_EDIT_SCHEDULE: 'supervisors_edit_schedule',
  CUSTOM_LOGO: 'custom_logo',
  ACT_HOURS_VERIFICATION: 'act_hours_verification',
  INTERNSHIPS_CARPOOLING: 'internships_carpooling',
  DRAFT_INTERNSHIPS_TYPE: 'draft_internships',
  SUPERVISOR_CAN_USE_SECTOR_FILES: 'supervisor_can_use_sector_files',
  DOMAIN_EVALUATORS: 'domain_evaluators'
})

// #endregion

// #region Genders

/** @enum {string} */
export const Genders = Object.freeze({
  M: 'M',
  F: 'F',
  OTHER: 'Other'
})

export const GENDER_OPTIONS = props => Object
  .entries(Genders)
  .map(([key, value]) => ({ id: key, name: props.t(value) }))

// #endregion

// #region Countries

/** @enum {string} */
export const Countries = Object.freeze({
  BE: 'Belgium',
  FR: 'France',
  LU: 'Luxembourg',
  NL: 'Netherlands',
  CH: 'Switzerland',
  Other: 'Other'
})

export const COUNTRIES = props => Object
  .entries(Countries)
  .map(([key, value]) => ({ id: key, name: props.t(value) }))

// #endregion

// #region Constraints

/** @enum {string} */
const InstitutionConstraintTypes = Object.freeze({
  INTERNSHIPS_BLOCKING: 'INTERNSHIPS_BLOCKING'
})

/** @enum {string} */
const EntityTypes = Object.freeze({
  STUDENT: 'Student'
})

// #endregion

// #region States

/** @enum {string} */
export const InternshipStates = Object.freeze({
  PRE_ACCEPTED: -3,
  PENDING_STUDENT_DATA_MISSING: -2,
  PENDING_NO_SECTOR: -1,
  CREATED: 0,
  PENDING: 1,
  ACCEPTED: 3,
  AUTO_ACCEPTED: 4,
  REFUSED: 5,
  CANCELED: 6,
  ACCEPTED_UNMANAGED: 7,
  SCHEDULE_ADDED: 8,
  SCHEDULE_VALIDATED: 9,
  SCHEDULE_TO_VALIDATE: 10
})

/** @enum {string} */
export const InternshipStatesString = Object.freeze({
  [InternshipStates.PRE_ACCEPTED]: 'PRE_ACCEPTED',
  [InternshipStates.PENDING_STUDENT_DATA_MISSING]: 'PENDING_STUDENT_DATA_MISSING',
  [InternshipStates.PENDING_NO_SECTOR]: 'PENDING_NO_SECTOR',
  [InternshipStates.CREATED]: 'CREATED',
  [InternshipStates.PENDING]: 'PENDING',
  [InternshipStates.ACCEPTED]: 'ACCEPTED',
  [InternshipStates.AUTO_ACCEPTED]: 'AUTO_ACCEPTED',
  [InternshipStates.REFUSED]: 'REFUSED',
  [InternshipStates.CANCELED]: 'CANCELED',
  [InternshipStates.ACCEPTED_UNMANAGED]: 'ACCEPTED_UNMANAGED',
  [InternshipStates.SCHEDULE_ADDED]: 'SCHEDULE_ADDED',
  [InternshipStates.SCHEDULE_VALIDATED]: 'SCHEDULE_VALIDATED',
  [InternshipStates.SCHEDULE_TO_VALIDATE]: 'SCHEDULE_TO_VALIDATE'
})

/** @enum {string} */
export const InternshipStatesAsString = Object.freeze({
  PRE_ACCEPTED: 'pre-accepted',
  ACCEPTED: 'accepted',
  CANCELED: 'canceled',
  PENDING: 'pending',
  REFUSED: 'refused',
  SCHEDULE_ADDED: 'schedule-added',
  TO_VALIDATE: 'to-validate',
  VALIDATED: 'validated',
  WITHOUT_INSTITUTION: 'without-institution'
})

/** @enum {string} */
export const InternshipStatesCanBeValidated = Object.freeze([
  InternshipStates.ACCEPTED,
  InternshipStates.AUTO_ACCEPTED,
  InternshipStates.ACCEPTED_UNMANAGED,
  InternshipStates.SCHEDULE_ADDED,
  InternshipStates.SCHEDULE_VALIDATED,
  InternshipStates.SCHEDULE_TO_VALIDATE
])

// #endregion

// #region Formats

const FULL_DATE_WITHOUT_TIMEZONE = 'YYYY-MM-DD HH:mm:ss'
const DATE_WITHOUT_TIME = 'YYYY-MM-DD'
const EUROPEAN_DATE = 'DD/MM/YYYY'
const SHORT_EUROPEAN_DATE = 'DD/MM'

export const ANTD_FORM_VALIDATE_STATUS = 'success'
export const ANTD_FORM_ERROR_STATUS = 'error'

export const SUCCESS_REQUEST_STATUS = 'success'
export const NULL_SECTION = 'global'

export const DATE_FORMAT_API = 'YYYY-MM-DD'
export const BACKEND_DATE_FORMAT = 'YYYY-MM-DDTHH:mm:ss'
export const URI_FORMAT = 'YYYY-MM-DD'
export const internshipStatesStringToNum = filterState => InternshipStates[filterState.replace('INTERNSHIP_STATE_', '')]
export const internshipOrderStringToShort = sortBy => sortBy.replace('SORT_BY_', '')

// #endregion

/** @enum {string} */
export const KeyboardKeys = Object.freeze({
  ARROW_DOWN: 'ArrowDown',
  ARROW_UP: 'ArrowUp',
  ARROW_LEFT: 'ArrowLeft',
  ARROW_RIGHT: 'ArrowRight'
})

export {
  FULL_DATE_WITHOUT_TIMEZONE,
  DATE_WITHOUT_TIME,
  EUROPEAN_DATE,
  SHORT_EUROPEAN_DATE,
  INTERNEO_FAQ_URL_PART_1,
  INTERNEO_FAQ_URL_PART_2,
  InstitutionConstraintTypes,
  EntityTypes
}
