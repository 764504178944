import React, { useCallback, useContext, useMemo } from 'react'
import EntitiesSelector from './EntitiesSelector'
import { InstitutionContext } from '../../../../Providers/InstitutionProvider'

/**
 * @param {object} params
 * @param {Array} params.values
 * @param {(any) => any} params.onChange
 * @param {(any) => boolean} [params.filter]
 * @param {object} [params.style]
 * @returns {JSX.Element}
 */
const SectorsSelector = ({ values, onChange, filter, style = {} }) => {
  const { sectors } = useContext(InstitutionContext)

  const mapToOptions = useCallback(sector => ({
    label: sector.name,
    value: sector.id,
    tooltip: sector.name,
    entity: sector
  }), [])

  const sectorOptions = useMemo(() => typeof filter === 'function'
    ? sectors.filter(filter).map(mapToOptions)
    : sectors.map(mapToOptions)
  , [sectors, filter])

  return (
    <EntitiesSelector
      onChange={onChange}
      entityOptions={sectorOptions}
      values={values}
      style={{ ...style }}
    />
  )
}

export default SectorsSelector
