import React, { useMemo } from 'react'

import EntitiesSelector from './EntitiesSelector'

const SchoolSectionSelector = ({ disabled, loading, schoolSections, style = {}, values, onChange }) => {
  const options = useMemo(() => schoolSections.map(s => ({
    label: s.schoolYearName ? `${s.abbreviation} (${s.schoolYearName})` : s.abbreviation,
    value: s.id,
    tooltip: s.name,
    entity: s
  })), [schoolSections])

  return (
    <EntitiesSelector
      disabled={disabled}
      entityOptions={options}
      loading={loading}
      multiple='default'
      showSearch
      style={style}
      values={values}
      onChange={onChange}
    />
  )
}

export default SchoolSectionSelector
