import { fetch } from '..'
import { makeQueryString, HOST_NAME, HttpMethods } from '../apiHelper'

const apiClient = Object.freeze({
  /** @param {object} user @param {string} url @param {object} queryParams */
  get: (user, url, queryParams = {}) => fetch(HOST_NAME + url + makeQueryString(queryParams), HttpMethods.GET, null, user),
  /** @param {object} user @param {string} url @param {object} body */
  post: (user, url, body) => fetch(HOST_NAME + url, HttpMethods.POST, body, user),
  /** @param {object} user @param {string} url @param {object} body */
  patch: (user, url, body) => fetch(HOST_NAME + url, HttpMethods.PATCH, body, user),
  /** @param {object} user @param {string} url @param {object} queryParams */
  delete: (user, url, queryParams) => fetch(HOST_NAME + url + makeQueryString(queryParams), HttpMethods.DELETE, null, user)
})

export default apiClient
