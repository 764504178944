import moment from 'moment'

/** @enum {string} */
export const Actions = Object.freeze({
  CREATE: 'CREATE',
  UPDATE: 'UPDATE',
  DELETE: 'DELETE'
})

/** @enum {string} */
export const Events = Object.freeze({
  CLICK: 'CLICK',
  DOUBLE_CLICK: 'DOUBLE_CLICK',
  DRAG_STOP: 'DRAG_STOP',
  LONG_CLICK: 'LONG_CLICK',
  DESELECT: 'DESELECT'
})

export const getXCoordinates = (quota, tablePeriods) => {
  const tableStartYear = moment(tablePeriods[0].startDate).year()
  const tableStartWeek = moment(tablePeriods[0].startDate).week()
  const quotaStartYear = moment(quota.startDate).year()
  const quotaStartWeek = moment(quota.startDate).week()
  const duration = moment(quota.endDate).diff(moment(quota.startDate), 'weeks')

  const max = tablePeriods.length - 1

  const startX = (quotaStartWeek + ((quotaStartYear - tableStartYear) * 52)) - tableStartWeek
  const endX = startX + duration > max ? max : startX + duration

  return {
    start: startX,
    end: endX
  }
}

export const getYCoordinates = (quota, index) => {
  return {
    start: index,
    end: index + (quota.total > 0 ? quota.total - 1 : 0)
  }
}

export const getDisplacement = (start, end, cellSize) => {
  return Math.round((end - start) / cellSize)
}

export const rectangleOverlap = (recA, recB) => {
  const widthIsPositive = Math.min(recA.x[1], recB.x[1]) >= Math.max(recA.x[0], recB.x[0])
  const heightIsPositive = Math.min(recA.y[1], recB.y[1]) >= Math.max(recA.y[0], recB.y[0])

  return widthIsPositive && heightIsPositive
}

export const getPixelIntVal = pixelVal => {
  return parseInt(pixelVal.replace('px', ''))
}
